import { Formik } from "formik";
import { FocusEvent, useContext, useEffect, useState } from "react";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import TextField from "../../component/TextField";
import DynamicComponentXN from "../../phan-he-xet-nghiem/components/DynamicComponentXN";
import { changeWfStatus } from "../../utils/AppFunction";
import {
  CODE,
  MENU_ACTION,
  SELECTION_MODE,
  STATUS_CANCEL,
  TERM_TYPE,
} from "../../utils/Constant";
import { cancelService } from "../../utils/ServicesUtils";
import WfContextMenu from "../../workflows/components/WfContextMenu";
import { WfConstTracker } from "../../workflows/Constant";
import { IDanhSachDVCdhaTdcn } from "../models/ModelsPhanHeCDHAVaTDCN";
import {
  createObsValue,
  inPhieuKetQuaCLS,
} from "../services/PhanHeCDHAVaTDCNService";
import CheckIcon from "../../phan-he-kham-benh/components/CheckIcon";
import ContextMenu from "../../phan-he-xet-nghiem/components/tab-lay-mau-benh-pham/ContextMenuReturnResult";
import WfButton from "../../workflows/components/WfButton";
import { Button } from "react-bootstrap";
import FormInPhieuDialog from "../../component/button-in-phieu/components/PrintDialog";
import { WORK_FLOW_STATUS_CDHA } from "../constants/Constants";
import { getWfWorkflows } from "../../workflows/services/WfWorkflowsService";

type Props = {
  dataDanhSachDVCdha: IDanhSachDVCdhaTdcn[];
  infoBnSelectedCdha?: any;
  setDsDichVu?: any;
  handleDoubleClick?: (row: any) => void;
  isViewModal?: boolean;
  handleGetInfoBn: (item?: any) => void;
  setStatusChangedEvent: (data: any) => void;
  workFlowStatus?: any;
  onRowSelect?: (row: any) => void;
};

const BangDsDichVuCDHA = (props: Props) => {
  let {
    infoBnSelectedCdha = {},
    setDsDichVu = () => {},
    handleDoubleClick = () => {},
    isViewModal,
    handleGetInfoBn,
    workFlowStatus,
    onRowSelect
  } = props;
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [rowSelect, setRowSelect] = useState<any>([]);
  const [dataSubmit, setDataSubmit] = useState(props.dataDanhSachDVCdha);
  const [fieldLoading, setFieldLoading] = useState<any>({});
  const { setIsLoading } = useContext(AppContext);
  const [configurationContextMenu, setConfigurationContextMenu] =
    useState<any>(null);
  const [dataDynamic, setDataDynamic] = useState<any>();
  const [itemService, setItemService] = useState<any>(null);
  const [currStatusCode, setCurrentStatusCode] = useState<any>(null);
  const [openPrintDialog, setOpenPrintDialog] = useState(false);
  const handleContextMenu = async (e: any, row: any) => {
    e.preventDefault();
    setRowSelect(row?.original);
    if (workFlowStatus === WORK_FLOW_STATUS_CDHA.CHUA_THUC_HIEN.code) {
      if (row?.original?.additionalConfiguration) {
        setConfigurationContextMenu(row?.original?.additionalConfiguration);
        setContextMenu({ x: e.clientX, y: e.clientY });
      } else {
        setConfigurationContextMenu(null);
      }
    } else {
      if (!row?.original?.obs?.conclusion) {
        toast.warning("Dịch vụ chưa có kết quả!");
        return;
      }
      let tracker = WfConstTracker.RISPACS_ORDER;
      let objectId = row?.original?.orderId;
      let { data } = await getWfWorkflows(tracker, objectId);
      if (data?.code === CODE.SUCCESS) {
        setItemService(data?.data);
        setConfigurationContextMenu(data?.data?.additionalConfiguration);
        setContextMenu({ x: e.clientX, y: e.clientY });
      } else {
        handleClearContext();
      }
    }
  };

  const actionClick = (data: any) => {
    setDataDynamic({ ...data });
  };

  const handleCancelService = async (value: any) => {
    setIsLoading(true);
    try {
      const obj = {
        termType: TERM_TYPE.CDHA,
        termOrderId: rowSelect?.termOrderId,
        cancelReason: value?.canceledReason,
        menuAction: MENU_ACTION.XN_CDHA,
        statusId: rowSelect?.oldStatusId,
      };

      let { data } = await cancelService(obj);
      if (CODE.SUCCESS === data?.code && data?.data) {
        toast.success("Bỏ dịch vụ thành công!");
        handleGetInfoBn(infoBnSelectedCdha);
      } else {
        toast.error(data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setDataSubmit(props.dataDanhSachDVCdha);
  }, [props.dataDanhSachDVCdha]);

  const handleSaveObs = async (rowData: any, value: any) => {
    try {
      if (value !== rowData?.obs?.conclusion) {
        setFieldLoading({ [rowData?.id]: true });
        const submitData = {
          personId: infoBnSelectedCdha?.patient?.personId,
          orderId: rowData?.orderId,
          values: {
            conclusion: value || null,
          },
        };

        await createObsValue(submitData);
        toast.success("Lưu kết luận thành công");

        const dsDichVu = props?.dataDanhSachDVCdha?.map((item: any) => {
          if (rowData?.id === item?.id) {
            return {
              ...item,
              termOrderId: item?.id,
              obs: {
                ...item?.obs,
                conclusion: value,
              },
            };
          }

          return {
            ...item,
            termOrderId: item?.id,
          };
        });
        setDsDichVu(dsDichVu);
      }
    } catch (error) {
      toast.warning("Lỗi lưu kết luận, vui lòng nhập lại!");
    } finally {
      setFieldLoading({});
    }
  };

  const generateInput = (rowData: any) => {
    return (
      <div className="d-flex align-items-center spaces w-100">
        <TextField
          className="spaces w-100"
          as="textarea"
          name="conclusion"
          defaultValue={rowData?.obs?.conclusion || ""}
          disabled={false}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            handleSaveObs(rowData, e.target.value);
          }}
        />
        {fieldLoading[rowData.id] && <span className="loading-spinner"></span>}
      </div>
    );
  };

  const columnsDSDVCdhaTdcn: ReadonlyArray<Column<any>> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={String(props?.row?.index + 1)}
          className={`text-center fs-14`}
          style={{ color: props.data[props.row.index]?.statusColor }}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"TÊN DỊCH VỤ"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className={`s-2 fs-14`}
          data={
            !props.data[props.row.index]?.isCanceled ? (
              <CheckIcon
                data={props.data[props.row.index]}
                color={props.data[props.row.index]?.state?.color}
                className="ml-2"
              />
            ) : (
              props.data[props.row.index]?.name
            )
          }
          style={{ color: props.data[props.row.index]?.statusColor }}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"SL"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "SL",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={
            props.data[props.row.index]?.quantity ||
            props.data[props.row.index]?.isCanceled
              ? 0
              : 1
          }
          className={`text-center fs-14`}
          style={{ color: props.data[props.row.index]?.statusColor }}
        />
      ),
    },
    // {
    //   Header: (props) => (
    //     <TableCustomHeader<any>
    //       tableProps={props}
    //       title={"#ACC"}
    //       className=" text-center text-white align-middle bg-pri min-w-100px"
    //     />
    //   ),
    //   id: "ACC",
    //   Cell: ({ ...props }) => (
    //     <TableCustomCell
    //       data={props.data[props.row.index]?.acc}
    //       className="text-center fs-14"
    //     />
    //   ),
    // },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"KẾT LUẬN"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "Kết luận",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={
            <Formik
              initialValues={{}}
              onSubmit={() => {}}
              className={`spaces w-100 fs-14`}
            >
              {isViewModal ||
              props.data[props.row.index]?.statusCode !==
                STATUS_CANCEL.EXCUTING ||
              props.data[props.row.index]?.state?.currStatusCode ===
                WORK_FLOW_STATUS_CDHA.DA_TRA_KQ_DICH_VU.code ? (
                <span
                  style={{ color: props.data[props.row.index]?.statusColor }}
                >
                  {props.data[props.row.index]?.obs?.conclusion}
                </span>
              ) : (
                generateInput(props.data[props.row.index])
              )}
            </Formik>
          }
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"MÁY THỰC HIỆN"}
          className=" text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "Máy thực hiện",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.mayThucHien}
          className={`text-center fs-14`}
          style={{ color: props.data[props.row.index]?.statusColor }}
        />
      ),
    },
  ];

  const handleClearContext = () => {
    setConfigurationContextMenu(null);
    setItemService(null);
    setCurrentStatusCode(null);
    setContextMenu?.(null);
  };

  const actionChanged = (currStatusCodeUpdate: string | undefined) => {
    if (currStatusCodeUpdate !== workFlowStatus) {
      switch (currStatusCodeUpdate) {
        case WORK_FLOW_STATUS_CDHA.HUY_TRA_KQ_DICH_VU.code:
          toast.success("Hủy trả kết quả thành công");
          break;
        case WORK_FLOW_STATUS_CDHA.DA_TRA_KQ_DICH_VU.code:
          toast.success("Trả kết quả thành công");
          break;
      }
    }
    handleGetInfoBn(infoBnSelectedCdha);
    handleClearContext();
  };

  return (
    <>
      <TableCustom<any>
        className={"h-bang-ds-bn spaces h-calc-vh-273"}
        data={props.dataDanhSachDVCdha || []}
        columns={columnsDSDVCdhaTdcn}
        selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
        handleDoubleClick={handleDoubleClick}
        verticalScroll={true}
        handleContextMenu={handleContextMenu}
        getSelectedRowsData={(selectedRows: any) => {
          onRowSelect?.({
              data: selectedRows?.[0]
          });
        }}
      />

      {contextMenu && !itemService && (
        <WfContextMenu
          tracker={WfConstTracker.ORDER_CIS}
          configuration={configurationContextMenu}
          wfWorkflowsReq={{
            objectId: rowSelect?.orderId,
            status: rowSelect?.oldStatusId,
          }}
          title="Phiếu thực hiện"
          contextMenu={contextMenu}
          setContextMenu={setContextMenu}
          handleClick={actionClick}
        />
      )}

      {itemService && !rowSelect?.isCanceled && (
        <ContextMenu
          handleCloseMenu={() => {
            handleClearContext();
          }}
          target={contextMenu}
          className="context-menu-xn"
          additionalFunc={actionChanged}
          handleClick={actionClick}
          tracker={itemService?.trackerCode}
          objectId={itemService?.objectId}
          ButtonProps={
            itemService.currStatusCode ===
            WORK_FLOW_STATUS_CDHA.DA_TRA_KQ_DICH_VU.code ? (
              <li
                id="wf-print-button"
                onClick={() => {
                  setOpenPrintDialog(true);
                  handleClearContext();
                }}
                className={`position-relative p-1 drop-list-item d-flex justify-content-between align-items-center border-bottom`}
              >
                <span className="fs-4 min-w-15px text-center">
                  <i className="bi bi-printer" />
                </span>
                <div className="d-flex justify-content-between align-items-center w-100 ps-2">
                  <span>In kết quả</span>
                </div>
              </li>
            ) : (
              <></>
            )
          }
        />
      )}

      {dataDynamic && (
        <DynamicComponentXN
          data={dataDynamic}
          setData={setDataDynamic}
          handleCancelService={handleCancelService}
        />
      )}

      {openPrintDialog && (
        <FormInPhieuDialog
          show={openPrintDialog}
          onHide={() => {
            setOpenPrintDialog(false);
          }}
          fetchExport={inPhieuKetQuaCLS}
          params={{
            orderTermId: rowSelect?.termId,
            risPacsGroupId: rowSelect?.rispacsGroupId,
          }}
        />
      )}
    </>
  );
};

export default BangDsDichVuCDHA;
