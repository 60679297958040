import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import AutocompleteV2 from "../../../component/AutocompleteObjectV2";
import { IconButtonSave } from "../../../component/IconSvg";
import LabelRequired from "../../../component/LabelRequired";
import TextField from "../../../component/TextField";
import { ObjectSelectAutocomplete } from "../../../phan-he-tiep-nhan-thanh-toan/models/PhanHeTiepNhanModel";
import { LEVEL, LOAI_PHONG } from "../../../utils/Constant";
import { getListKhoaPhong } from "../../../utils/ServicesUtils";
import InfoPatientRight from "../../components/InfoPatientRight";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";

type Props = {
    open: boolean;
    handleCloseModal: () => void;
    handleSubmit: (values: any) => void;
    modalityDetails?: any;
};
interface IChuyenPhongKham {
    treatmentDept?: ObjectSelectAutocomplete | null;
    treatmentReason?: string;
}
const ModalNhapVien = ({ open, handleCloseModal, handleSubmit, modalityDetails }: Props) => {
    const intl = useIntl();
    const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
    let infoBenhNhan: any = benhNhanInfo;
    const [dsKhoa, setDsKhoa] = useState<any>([]);

    const validationSchema = Yup.object().shape({
        treatmentDept: Yup.object().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    });

    const handleGetDsDanhMuc = async () => {
        try {
            const { data } = await getListKhoaPhong({ level: LEVEL.KHOA, type: LOAI_PHONG.PHONG_KHAM_BENH });
            setDsKhoa(data?.data?.content || []);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmitForm = (values: IChuyenPhongKham) => {
        let submitData = {
            treatmentReason: values?.treatmentReason,
            treatmentDeptCode: values?.treatmentDept?.code,
            treatmentDeptName: values?.treatmentDept?.name
        };
        handleSubmit({
            ...modalityDetails,
            ...submitData
        });
    };

    useEffect(() => {
        handleGetDsDanhMuc();
        return () => { };
    }, []);

    return (
        <Modal centered className="modal fade" role="dialog" show={open} dialogClassName="modal-lg" aria-hidden="true">
            <Formik<IChuyenPhongKham>
                initialValues={{
                    treatmentDept: null,
                    treatmentReason: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmitForm}
            >
                {({ values, setFieldValue, handleChange, errors, touched }) => (
                    <Form className="h-100">
                        <ModalHeader closeButton onHide={handleCloseModal} className="header-modal">
                            <Modal.Title>Nhập viện</Modal.Title>
                        </ModalHeader>

                        <ModalBody className="overflow-hidden spaces px-10">
                            <InfoPatientRight benhNhanInfo={infoBenhNhan} />

                            <Row className="spaces pt-10">
                                <LabelRequired label="Khoa điều trị" isRequired />
                                <AutocompleteV2
                                    options={dsKhoa}
                                    name="treatmentDept"
                                    onChange={(option) => setFieldValue("treatmentDept", option)}
                                    getOptionLabel={(option) => `${option.code} - ${option.name}`}
                                    value={values?.treatmentDept}
                                    touched={touched?.treatmentDept}
                                    errors={errors?.treatmentDept}
                                />
                            </Row>

                            <Row className="spaces pt-10">
                                <Col xs={12}>
                                    <LabelRequired label="Lý do vào viện" />
                                    <TextField
                                        className="w-100 d-flex resize-none"
                                        name="treatmentReason"
                                        labelClassName="ms-0 min-w-145px"
                                        as="textarea"
                                        rows="5"
                                        value={values?.treatmentReason || ""}
                                        onChange={handleChange}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>

                        <ModalFooter className="d-flex justify-content-end py-2">
                            <Button className="btn-fill" type="submit">
                                <IconButtonSave /> Lưu
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ModalNhapVien;
