import { Col, Row } from "react-bootstrap"
import { useEffect, useState } from "react"
import GenerateFormHTMLNoValid from "../../../../component/generate-form/GenerateFormHTMLNoValid"
import { getJsonGenerateForms } from "../../../../utils/ServicesUtils"
import { useFormikContext } from "formik"

type Props = {
    formName: string;
}

const DynamicConentTab = ({
    formName
}: Props) => {
    const [dataGenerate, setDataGenerate] = useState<any>();
    const { values, setFieldValue } = useFormikContext<any>();
    const isVisitStopped = values?.visit?.stopped;

    const handleChange = (name: string, value: any, type?: string) => {
        if (type === "select") {
            let stringName = name + "Name";
            let stringCode = name;
            setFieldValue(`additionalForms.${formName}`, {
                ...values?.additionalForms?.[formName],
                [stringName]: value?.name,
                [stringCode]: value?.code,
            });
        } else {
            setFieldValue(`additionalForms.${formName}.${name}`, value);
        }
    }

    const handleGetJsonGenerateForms = async () => {
        try {
            let { data } = await getJsonGenerateForms(formName);
            setDataGenerate(data?.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        handleGetJsonGenerateForms();
    }, []);

    return (
        <Row className="spaces h-calc-vh-510 p-12">
            <Col xl="12" className="h-100">
                <GenerateFormHTMLNoValid
                    isView={isVisitStopped}
                    listFieldAuto={JSON.parse(dataGenerate?.[formName] as string || "{}")}
                    values={values?.additionalForms}
                    isSave={false}
                    isUpdate={true}
                    onChange={handleChange}
                    modelID={formName}
                />
            </Col>
        </Row>
    )
}

export default DynamicConentTab