import { OctAutocomplete } from '@oceantech/oceantech-ui';
import { useContext, useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AppContext } from '../../appContext/AppContext';
import FilterSidebar from '../../component/filter-sidebar/FilterSidebar';
import LazyLoadTable from '../../component/lazy-load-table/LazyLoadTable';
import {
	CODE,
	DEFAULT_PAGE_INDEX,
	LEVEL,
	LOAI_PHONG,
	RESPONSE_MESSAGE,
} from '../../utils/Constant';
import { formatDateToYYYYMMDD } from '../../utils/FormatUtils';
import { getListKhoaPhong } from '../../utils/ServicesUtils';
import { WfSummaryStatusRes } from '../../workflows/models/WfWorkflows';
import { COLUMN_PHIEU_LAY_MAU, DANH_SACH_BENH_NHAN_FILTER_OPTIONS } from '../constants';
import { usePhanHeLayMauContext } from '../context/PhanHeLayMauContext';
import {
	getBenhNhanInfo,
	getDanhSachBenhNhan,
	getPhieuLayMauInfo,
	getStatusSummary,
} from '../services';
import { IContextMenu } from '../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy';
import ContextMenu from '../../component/ContextMenuV3';
import { speaker } from '../../utils/Speaker';
import withBarcodeScan from '../../../hoc/withBarcodeScan';

type Props = {
	statusChangedEvent: any;
	barcode?: string;
};

const DanhSachBenhNhan = (props: Props) => {
	const { setPhieuLayMauInfo, setBenhNhanInfo, selectedBenhNhan, setSelectedBenhNhan } =
		usePhanHeLayMauContext();
	const { setIsLoading } = useContext(AppContext);

	const [summaryStatus, setSummaryStatus] = useState<WfSummaryStatusRes[]>([]);
	const [searchObject, setSearchObject] = useState<any>(null);
	const [listFilter, setListFilter] = useState<any>(null);
	const [contextMenu, setContextMenu] = useState<null | {
		x: number;
		y: number;
	  }>(null);
	const [rowSeclectedByContextMenu, setRowSeclectedByContextMenu] = useState<any>();
	const departmentRef = useRef<any>(null);
	const filterRef = useRef<any>(null);
	const CODE_MENU = {
		GOI_BN: "goiBn",
	  };
	
	const dropListChiDinhDichVu: IContextMenu[] = [
		{
		  title: "Lấy mẫu",
		},
		{
		  icon: <i className="bi bi-volume-up-fill text-pri"></i>,
		  code: CODE_MENU.GOI_BN,
		  name: "Gọi bệnh nhân",
		},
	  ];

	  const handleContextMenu = (e: any, row: any) => {
			setRowSeclectedByContextMenu(row);
			setContextMenu({ x: e.clientX, y: e.clientY });
	  };

	const handleGoiBN = (data: any) => {
		let stt = data?.samplingOrdinalNumber;
		let name = data?.patientName;
		let diaDiem = data?.samplingDeptName;
		if (stt && diaDiem) {
			let content = `Mời khách hàng, ${name}, tới ${diaDiem})`;
			speaker.handleSpeak(content);
		} else {
			toast.warning("Bệnh nhân không đủ thông tin phòng lấy mẫu");
		}
	};
	const handleClickOptionContextMenu = (value: any) => {
		const menuActions = {
			[CODE_MENU.GOI_BN]: () => handleGoiBN(rowSeclectedByContextMenu),
		};
		menuActions[value?.code]?.();
		setContextMenu(null);
	};
	useEffect(() => {
		listFilter &&
			setSearchObject({
				...listFilter,
				order: null,
			});
	}, [listFilter]);

	useEffect(() => {
		searchObject && getDanhSachTraiThaiXN(searchObject);
	}, [props.statusChangedEvent, searchObject]);

	useEffect(() => {
	  if (props?.barcode) {
		searchBarcode(props.barcode);
	  }
	}, [props?.barcode]);

	const getDanhSachTraiThaiXN = async (searchData?: any) => {
		try {
			const searchObject = {
				pageIndex: DEFAULT_PAGE_INDEX,
				pageSize: 100,
				...searchData,
			};

			const { data, code } = await getStatusSummary(searchObject);

			if (CODE.SUCCESS === code) {
				setSummaryStatus(data || []);
				return;
			}
		} catch (error) {
			console.error(error);
			toast.warning(RESPONSE_MESSAGE.ERROR);
		}
	};

	const handleSelectedBenhNhan = async (benhNhan: any, callbackFn?: () => void) => {
		try {
			setIsLoading(true);

			const getDetailBenhNhan = getBenhNhanInfo(benhNhan?.id);
			const getDetailPhieu = getPhieuLayMauInfo(benhNhan?.id);

			const response = await Promise.all([getDetailBenhNhan, getDetailPhieu]);
			const [dataBenhNhan, dataPhieuLayMau] = response;
			const hasError = [dataBenhNhan.code, dataPhieuLayMau.code].some(
				code => code !== CODE.SUCCESS
			);

			if (hasError) {
				toast.warning(RESPONSE_MESSAGE.ERROR);
				return;
			}

			const thongTinPhieu = dataPhieuLayMau?.data;
			const danhSachMau = thongTinPhieu?.orders?.map((item: any) => ({
				...item,
				...item?.term,
				statusCode: item?.statusCode,
				statusColor: item?.statusColor,
				subs: item?.term?.subs?.map((sub: any) => ({
					...sub,
					orderId: item?.orderId,
					statusCode: item?.statusCode,
					statusColor: item?.statusColor,
				})),
			}));
			setSelectedBenhNhan(benhNhan);
			setBenhNhanInfo({
				...dataBenhNhan.data,
				id: benhNhan?.id,
				code: benhNhan?.code,
				samplingDeptCode: benhNhan?.samplingDeptCode,
			});
			setPhieuLayMauInfo({
				thongTinPhieu,
				danhSachMau,
			});
			callbackFn?.();
		} catch (error) {
			console.error(error);
			toast.warning('Xảy ra lỗi, vui lòng thử lại!');
		} finally {
			setIsLoading(false);
		}
	};

	const doSearch = (props: any) => {
		const searchObject = {
			keyword: props?.keyword || null,
			barcode: props?.additionalFieldSearch || null,
			sortBy: props.formal?.value || listFilter?.sortBy || null,
			descending: props.order?.value === 'DERCEASE' || !props.order,
			order: props.order,
			fromDate: formatDateToYYYYMMDD(props.period?.fromDate) || listFilter?.fromDate,
			toDate: formatDateToYYYYMMDD(props.period?.toDate) || listFilter?.toDate,
			filterBy: props?.filterBy || listFilter?.filterBy || null,
			filterValue: props?.filterValue || listFilter?.filterValue || null,
			samplingDeptCode: departmentRef.current || null,
		};
		filterRef.current = searchObject;
		setListFilter({
			...searchObject,
		});
	};

	const searchBarcode = async (value: string) => { 
		try {
			const { data } = await getDanhSachBenhNhan({
				...filterRef.current,
				keyword: value,
			});
			
			if (CODE.SUCCESS === data?.code) {
				const patientSelected = data?.data?.content?.[0];

				if (!patientSelected) {
					toast.warn('Không tìm thấy thông tin phiếu với mã bệnh nhân: ' + value);
					return;
				}
					
				handleSelectedBenhNhan(patientSelected, () => {
					toast.success(`Lấy thông tin phiếu với mã bệnh nhân: ${value} thành công`);
				});
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	return (
		<>
			<FilterSidebar
				title="Danh sách lấy mẫu"
				formalList={DANH_SACH_BENH_NHAN_FILTER_OPTIONS}
				onSearch={doSearch}
				listMenuItem={[]}
				handleSelectOption={() => {}}
				additionalTextSearch={{
					name: 'barcode',
					placeholder: 'Barcode',
				}}
			/>

			<div className="spaces h-calc-vh-170 d-flex flex-column justify-content-between pt-10">
				<div className="spaces">
					<LazyLoadTable
						columns={COLUMN_PHIEU_LAY_MAU}
						axiosReq={getDanhSachBenhNhan}
						searchData={searchObject}
						urlData="data.data.content"
						className="spaces h-calc-vh-261"
						onRowClick={row => handleSelectedBenhNhan(row)}
						handleContextMenu={handleContextMenu}
						dependencies={[props.statusChangedEvent]}
						rowSeclected={selectedBenhNhan}
						rowKey="index"
					/>
					{contextMenu && (
						<ContextMenu
							handleClickOptionContextMenu={handleClickOptionContextMenu}
							handleCloseMenu={() => setContextMenu(null)}
							data={dropListChiDinhDichVu}
							target={contextMenu}
						/>
						)}
				</div>
				<Row className="d-flex spaces w-100">
					<Col xs={12} className="spaces ps-5 pb-3 pr-0">
						<OctAutocomplete
							options={[]}
							placeholder="Tất cả khoa phòng"
							menuPlacement="top"
							searchFunction={getListKhoaPhong}
							searchObject={{
								level: LEVEL.PHONG,
								type: LOAI_PHONG.LAY_MAU,
							}}
							onChange={option => {
								departmentRef.current = option?.code;
								doSearch({
									...listFilter,
								});
							}}
						/>
					</Col>
				</Row>
				<Row className="count-status-xet-nghiem spaces w-100 gap-10 px-5 justify-content-center mx-auto">
					{summaryStatus.map((item, index) => (
						<div
							key={index}
							className="d-flex status text-white rounded spaces px-8 py-4 w-100 justify-content-center cursor-pointer"
							style={{ backgroundColor: item.color }}
							onClick={() => {
								doSearch({
									...listFilter,
									filterBy: 'currStatusCode',
									filterValue: item?.statusCode,
								});
							}}
						>
							<span className="body-normal-1 spaces">{`${item.statusName}: ${item.summary}`}</span>
						</div>
					))}
				</Row>
			</div>
		</>
	);
};

export default withBarcodeScan(DanhSachBenhNhan);
