import { KeyboardEvent, useRef } from 'react';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';

type Props = {
	onReadData: (value: string) => void | Promise<any>;
	disabled?: boolean;
	className?: string;
	label?: string;
	tooltip?:string;
};

const ScanQRCccdButton = ({
	onReadData,
	disabled,
	label = 'Thông tin trên mã QR căn cước công dân:',
	tooltip = 'Quét QR căn cước công dân',
}: Props) => {
	const inputRef = useRef<HTMLTextAreaElement>(null);
	const buttonRef = useRef<HTMLButtonElement>(null);

	const onReadQRCode = () => {
		const QRStringValue = inputRef.current!.value;

		if (!QRStringValue) {
			toast.warn('Chưa có thông tin mã QR');
			inputRef.current?.focus();
			return;
		}

		onReadData(QRStringValue);

		buttonRef.current?.click();
	};

	const onReset = () => {
		if (inputRef.current) {
			inputRef.current.value = '';
			inputRef.current.focus();
		}
	};

	const popover = (
		<Popover id="popover-basic" className="spaces min-w-300">
			<Popover.Body className="spaces w-100 p-8">
				<Form.Group className="mb-3">
					<Form.Label>{label}</Form.Label>
					<Form.Control
						ref={inputRef}
						as="textarea"
						rows={4}
						autoFocus
						onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => {
							if (e.key === 'Enter') {
								onReadQRCode();
							}
						}}
					/>
				</Form.Group>
				<div className="d-flex justify-content-end gap-3">
					<Button className="btn-blue-outlined-custom" onClick={onReset}>
						Quét lại
					</Button>
					<Button className="btn-fill" onClick={onReadQRCode}>
						Xác nhận
					</Button>
				</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<OverlayTrigger
			delay={{ show: 0, hide: 0 }}
			placement="bottom"
			rootClose
			overlay={popover}
			trigger={['click']}
		>
			<Button
				ref={buttonRef}
				className="btn-fill spaces ml-4"
				disabled={disabled}
				title={tooltip}
			>
				<i className="bi bi-qr-code-scan spaces mr-0 pr-4 pl-4"></i>
			</Button>
		</OverlayTrigger>
	);
};

export default ScanQRCccdButton;
