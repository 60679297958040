import {
  FormikErrors,
  FormikProps,
  FormikTouched,
  useFormikContext,
} from "formik";
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { removeEventEnter } from "../../utils/AppFunction";
import { CODE, ID_DANG_KY_KHAM, KEY, KEY_DS_DANH_MUC_TIEP_DON, VARIABLE_STRING } from "../../utils/Constant";
import { formatDateDTO, handleAgeCalculate } from "../../utils/FormatUtils";
import { PhanHeTiepNhanContext } from "../PhanHeTiepNhan";
import TTHanhChinhV2 from "../components/tab-tiep-nhan/TTHanhChinhV2";
import TTKhamBenhV2 from "../components/tab-tiep-nhan/TTKhamBenhV2";
import {
  CODE_DOI_TUONG,
  defaultOptionSelect,
  initialValuesTiepDon,
} from "../constants/PhanHeTiepNhan";
import { DialogDanhSachHenKham } from "../modal-danh-sach-hen-kham/DialogDanhSachHenKham";
import {
  ObjectSelectAutocomplete,
} from "../models/PhanHeTiepNhanModel";
import { IconButtonSave } from "../../component/IconSvg";
import { IDanhSachDanhMuc, ITiepDon } from "../models/TiepDonModel";
import DSBenhNhan from "../components/tab-tiep-nhan/DSBenhNhan";
import { getByIdBenhNhan, inPhieuKham } from "../services/TiepNhanServices";
import { getIndexedDBItem } from "../../utils/IndexedDB";
import { DEFAULT_CODE_SELECT_TIEPDON } from "../constants/constants";
import ButtonInPhieu from "../../component/button-in-phieu";

export type benhNhanProps = {
  setActiveTab?:any
  values: ITiepDon;
  setFieldValue: (field: string, value: any) => void;
  handleInputChangeSinhHieu: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChecked?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: FormikErrors<ITiepDon>;
  touched?: FormikTouched<ITiepDon>;
  DSDichVu?: any[];
  setValues?: (value: ITiepDon) => void;
  onAgeCalculate?: (DOB: string) => string;
  handleClearValue: (
    name: string,
    setFieldValue: (field: string, value: any) => void
  ) => void;
  handleChangeSelect: (
    selectedObject: ObjectSelectAutocomplete,
    name: string,
    setFieldValue: (field: string, value: any) => void
  ) => void;
  handleOpenTKBNDialog?: () => void;
  isDatLichHen?: boolean;
  handleChangeHenKham?: (value: boolean) => void;
  handleGenerateMaBenhNhan?: () => Promise<any>;
  resetForm?: () => void;
};

type TiepNhanProps = {
  openDSBenhNhan?: boolean,
  handleCloseDSBenhNhan?: () => void
}

const TiepNhan: FC<TiepNhanProps> = ({
  openDSBenhNhan, handleCloseDSBenhNhan
}) => {
  const { setIsLoading } = useContext(AppContext);
  const {
    thongTinBenhNhan,
    setDSDichVu,
    DSDichVu,
    setThongTinBenhNhan,
    setIsPrint,
    setOrderMain,
    convertDataBenhNhan
  } = useContext(PhanHeTiepNhanContext);
  const {
    handleSubmit,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
  } = useFormikContext<ITiepDon>();
  const [isDatLichHen, setIsHanKham] = useState<boolean>(false);
  const [openHenKham, setOpenDSHenKham] = useState(false);
  const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>("");

  const handleInputChangeSinhHieu = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      values: ITiepDon,
      setFieldValue: FormikProps<ITiepDon>["setFieldValue"]
    ) => {
      let { name, value, type } = e.target;
      let itemSinhHieu = {
        code: name,
        datatype: type,
        value: Number(value) > 0 && value,
        datetime: formatDateDTO(new Date().toString()),
      };
      setFieldValue(`obs.${name}`, itemSinhHieu);
    },
    []
  );

  const handleChecked = (
    (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: FormikProps<ITiepDon>["setFieldValue"]) => {
      const { name, checked } = e.target;
      setFieldValue(`${name}`, checked);
    }
  );

  const handleChangeSelect = (
    selectedObject: ObjectSelectAutocomplete,
    name: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    switch (name) {
      case VARIABLE_STRING.GENDER:
        setFieldValue("person.gender", selectedObject);
        break;

      default:
        setFieldValue(`${name}`, selectedObject);
        break;
    }
  };

  const handleClearValue = (
    name: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue(`${name}`, defaultOptionSelect);
  };

  const handleClearForm = async (
    setValues: (values: any) => void,
    setTouched: (touched: object) => void
  ) => {
    try {
      const res: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
      let listDoiTuong = res?.listDoiTuong || [];
      const listGioiTinh = res?.listGioiTinh || [];
      const listDanToc = res?.listDanToc || [];
      const listQuocTich = res?.listQuocTich || [];
      const listNgheNghiep = res?.listNgheNghiep || [];
      const listDKKham = res?.listDKKham || [];

      setValues({
        ...initialValuesTiepDon,
        visit: {
          ...initialValuesTiepDon.visit,
          visitObjectType: listDoiTuong?.find(
            (item: any) => item?.code === CODE_DOI_TUONG.YEU_CAU
          ) || null,
          visitType: listDKKham.find((item: any) => item?.id === ID_DANG_KY_KHAM.KHAM_BENH),
          attributes: {
            ...initialValuesTiepDon.visit?.attributes,
            examLevel: values?.visit?.attributes?.examLevel,
          },
        },
        patient: {
          ...initialValuesTiepDon?.patient,
          ethnicity: listDanToc.find((item: any) => item?.code === DEFAULT_CODE_SELECT_TIEPDON.DAN_TOC_KINH),
          occupation: listNgheNghiep.find((item: any) => item?.code === DEFAULT_CODE_SELECT_TIEPDON.NGHE_NGHIEP_NHAN_DAN),
          person: {
            ...initialValuesTiepDon?.patient?.person,
            ccountry: listQuocTich.find((item: any) => item?.code === DEFAULT_CODE_SELECT_TIEPDON.QUOC_TICH_VN),
            gender: listGioiTinh.find((item: any) => item?.code === DEFAULT_CODE_SELECT_TIEPDON.GIOI_TINH),
          },
        },
        orders: initialValuesTiepDon.orders,
      });
      setOrderMain(null);
      setTouched({});
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrint = () => {
    if (values?.id && setIsPrint) {
      setIsPrint(true);
    } else {
      toast.warn("Chưa có thông tin bệnh nhân");
    }
  };

  const [activeTab, setActiveTab] = useState("")
  const formTiepNhanRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    formTiepNhanRef.current && formTiepNhanRef.current.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
    })
  }, [activeTab]);

  const handleUpdateDataBenhNhan = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getByIdBenhNhan(id);
      if (CODE.SUCCESS === data?.code) {
        let dataConverted = convertDataBenhNhan(data?.data);
        let orderMain = data?.data?.orders?.find((order: any) => order?.fulfillDeptId);
        if (orderMain) {
          setOrderMain(orderMain);
        }
        setValues(dataConverted);
        setThongTinBenhNhan({
          ...thongTinBenhNhan,
          isExamined: data?.data?.isExamined,
          isMedicalExamSvcPaid: data?.data?.isMedicalExamSvcPaid,
        });
      } else {
        toast.error("Xảy ra lỗi, vui lòng thử lại!");
      }
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewPatient = () => {
    handleClearForm(setValues, setTouched);
    setDSDichVu([]);
    setThongTinBenhNhan({
      ...thongTinBenhNhan,
      isExamined: false,
      isMedicalExamSvcPaid: false,
    });
  }

  const handlePressKeyShortcuts = (e: any) => {
    switch (e.key) {
      case KEY.F1:
        e.preventDefault();
        handleNewPatient();
        break;
      case KEY.F2:
        e.preventDefault();
        handleSubmit();
        break;
      case KEY.F3:
        e.preventDefault();
        setKeyOpenPhieuIn("F3");
        break;
      default: return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handlePressKeyShortcuts);
    return () => {
      window.removeEventListener("keydown", handlePressKeyShortcuts);
    };
  }, []);

  return (
    <div className="d-flex">
      <Form className="receive overflow-auto" onSubmit={handleSubmit} onKeyDown={removeEventEnter}>
      <div className="form-tiep-nhan" ref={formTiepNhanRef} >
        <Row className="spaces p-10 bg-white">
          <TTHanhChinhV2
            values={values}
            setValues={setValues}
            setFieldValue={setFieldValue}
            handleInputChangeSinhHieu={(e: any) =>
              handleInputChangeSinhHieu(e, values, setFieldValue)
            }
            errors={errors}
            touched={touched}
            onAgeCalculate={handleAgeCalculate}
            handleChangeSelect={handleChangeSelect}
            handleClearValue={handleClearValue}
            isDatLichHen={isDatLichHen}
            DSDichVu={DSDichVu}
            handleChecked={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChecked(e, setFieldValue)
            }
            resetForm={resetForm}
          />
        </Row>

          <div className="spaces w-100 d-flex justify-content-start mt-10 bg-white gap-6 py-10 pr-10 pl-13">
            <Button
              className="btn-blue-outlined-custom spaces min-w-140 mr-5"
              onClick={handleNewPatient}
            >
              <i className="bi bi-plus-lg color-blue-custom fs-4"></i>
              <span>Bệnh nhân mới (F1)</span>
            </Button>
            {!values?.visit?.stopped && (
              <Button className="btn-fill spaces min-w-86" type="submit">
                <IconButtonSave />
                {(values?.patient?.id && values?.visit?.id) ? (
                  <span>Cập nhật (F2)</span>
                ) : (
                  <span>Lưu lại (F2)</span>
                )}
              </Button>
            )}
              {(values?.patient?.id && values?.visit?.id) && (
                <ButtonInPhieu
                label="In phiếu khám (F3)"
                fetchExport={inPhieuKham}
                params={{
                  encounterId : values?.encounter?.id,
                }}
                className="min-w-80px spaces mx-4"
                keyOpenPhieuIn={keyOpenPhieuIn}
                setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                keyBtn={KEY.F3}
              />
              )}
          </div>

        <TTKhamBenhV2
          setActiveTab={setActiveTab}
          values={values}
          setFieldValue={setFieldValue}
          handleInputChangeSinhHieu={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChangeSinhHieu(e, values, setFieldValue)
          }
          handleChecked={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChecked(e, setFieldValue)
          }
          errors={errors}
          touched={touched}
          DSDichVu={DSDichVu}
          setValues={setValues}
          handleChangeSelect={handleChangeSelect}
          handleClearValue={handleClearValue}
          isDatLichHen={isDatLichHen}
          handleChangeHenKham={(value) => setIsHanKham(value)}
        />
      </div>

        
        {openHenKham && (
          <DialogDanhSachHenKham show={openHenKham} onHide={setOpenDSHenKham} />
        )}
        {/* {openTimKiemBNDialog && (
          <ModalTimKiemBenhNhan
            onSelectedPatient={(patient) => getPatientInformation(setValues, patient)}
            open={openTimKiemBNDialog}
            handleClose={handleCloseTKBNDialog}
          />
        )} */}
      </Form>

      {openDSBenhNhan && (
        <DSBenhNhan
          show={openDSBenhNhan}
          onHide={handleCloseDSBenhNhan as () => void}
          handleGetThongTinBenhNhan={handleUpdateDataBenhNhan}
          resetFormTiepDon={handleNewPatient}
        />
      )}
    </div>
  );
};
export default TiepNhan;
