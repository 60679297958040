import { FC } from 'react';
import { ConfirmDialog } from '../../component/ConfirmDialog';
import { ConfirmReasonDialog } from '../../component/ConfirmReasonDialog';
import PageChiDinhDichVu from '../modals/modal-chi-dinh-dich-vu/PageChiDinhDichVu';

const DynamicComponentKB: FC<{
    data: any;
    setData: (data: any) => void;
    onConfirm: () => void;
    handleCancelService?: (value: any) => void;
}> = (props) => {
    const { data, setData, handleCancelService } = props;

    const COMPONENTNAMES_CONST = {
        CONFIRM: "Confirm",
        CHIDINHDICHVU: "ChiDinhDichVu",
        CONFIRMCANCEL: "ConfirmCancel"
    }

    const components: { [key: string]: JSX.Element } = {
        [COMPONENTNAMES_CONST.CONFIRM]: <ConfirmDialog
            show={data?.componentName === COMPONENTNAMES_CONST.CONFIRM}
            title='Xác nhận'
            message='Bạn có chắc chắn muốn xóa phiếu không?'
            yes='Có'
            close='Không'
            onYesClick={() => {
                props?.onConfirm();
                setData(null);
            }}
            onCloseClick={() => {
                setData(null);
                data?.cancel();
            }}
        />,
        [COMPONENTNAMES_CONST.CHIDINHDICHVU]: <PageChiDinhDichVu
            show={data?.componentName === COMPONENTNAMES_CONST.CHIDINHDICHVU}
            handleClose={() => {
                setData(null);
                data?.cancel();
            }}
            orderGroupId={data?.orderGroupId}
        />,
        [COMPONENTNAMES_CONST.CONFIRMCANCEL]: <ConfirmReasonDialog
            show={data?.componentName === COMPONENTNAMES_CONST.CONFIRMCANCEL}
            close="Hủy"
            yes="Lưu"
            onYesClick={(values) => {
                handleCancelService?.(values);
                setData(null);
            }}
            onCloseClick={() => setData(null)}
            title="Không thực hiện dịch vụ"
            name="canceledReason"
            label="Lý do không thực hiện dịch vụ"
        />
    };

    return components[data?.componentName];
};

export default DynamicComponentKB;
