import { OctTable } from '@oceantech/oceantech-ui';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LichSuKhamColumn } from '../../../phan-he-tiep-nhan-thanh-toan/components/tab-tiep-nhan/tab-lich-su-kham/LichSuKhamColumn';
import { getListSu } from '../../../phan-he-tiep-nhan-thanh-toan/services/TiepNhanServices';
import { RESPONSE_MESSAGE } from '../../../utils/Constant';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';

const TabLichSuKhamBenh = () => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

	const [tableData, setTableData] = useState<any[]>([]);

	const { patient } = benhNhanInfo?.thongTinKhamBenh ?? {};

	useEffect(() => {
		if (patient?.id) {
			getTableData(patient.id);
		}
	}, [patient?.id]);

	const getTableData = async (patientId: string) => {
		try {
			const { data } = await getListSu(patientId);
			setTableData(data?.data);
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	return (
		<div className="spaces p-10 bg-white h-calc-vh-300">
			<div className="spaces h-calc-vh-315">
				<OctTable
					id="list-patients"
					className="h-100 table-background-th"
					data={tableData}
					columns={LichSuKhamColumn}
					notDelete={true}
					notEdit={false}
					noToolbar={true}
					fixedColumnsCount={-1}
					isShowSelection={false}
					clearToolbar={true}
					noPagination
				/>
			</div>
		</div>
	);
};

export default TabLichSuKhamBenh;
