import {
  Form as FormFormik,
  Formik,
  FormikErrors,
  useFormikContext,
} from "formik";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../appContext/AppContext";
import { IDsNhanVien } from "../models/VienPhiInfoModels";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import {
  createSoThuTien,
  getThongTinSoThuTien,
  updateSoThuTien,
} from "../services/PhanHeVienPhiServices";
import {
  CODE,
  RESPONSE_MESSAGE,
  SIMPLE_CATEGORY_TYPE,
} from "../../utils/Constant";
import LabelRequired from "../../component/LabelRequired";
import TextField from "../../component/TextField";
import AutocompleteV2 from "../../component/AutocompleteObjectV2";
import { getSimpleCategory, getUsers } from "../../utils/ServicesUtils";
import ModalChonNhanVien from "../../phan-he-tiep-nhan-thanh-toan/components/tab-thanh-toan/modal-so-thu/ModalChonNhanVien";
import { useIntl } from "react-intl";
import { formatDateToYYYYMMDD } from "../../utils/FormatUtils";
import { KEY_AUTHORITIES } from "../../utils/Permission";
type Props = {
  show: boolean;
  onHide: Dispatch<
    SetStateAction<{
      openChonSoThu: boolean;
      openDanhSachSoThu: boolean;
      openTaoMoiSoThu: boolean;
    }>
  >;
  openDanhSachSoThu: boolean;
  setOpenDanhSachSoThu: Dispatch<SetStateAction<boolean>>;
  dataSoThu: any;
};

const ModalTaoSoThu: FC<Props> = (props) => {
  const { show, onHide, openDanhSachSoThu, setOpenDanhSachSoThu, dataSoThu } =
    props;
  const { setIsLoading } = useContext(AppContext);
  const intl = useIntl();
  let currentUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);
  const initialValues = {
    name: "",
    code: "",
    invStartNumber: "",
    invTotalNumber: "",
    vat: "",
    ledgerType: null,
  };
  let validationSchema = Yup.object({
    name: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
    code: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
    invStartNumber: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
    invTotalNumber: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
    ledgerType: Yup.object()
      .nullable()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" })),
  });
  const handleFormSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      let userNames = values?.userUsernames
        ? values?.userUsernames?.map((item: any) => item?.username)
        : [`${currentUser?.sub}`];
      let userUsernames = values?.userUsernames
        ? values?.userUsernames?.map((item: any) => item?.username)
        : [`${currentUser?.sub}`];
      let submitData = {
        ledgerTypeId: values?.ledgerType?.id,
        ledgerTypeCode: values?.ledgerType?.code,
        ledgerTypeName: values?.ledgerType?.name,
        createdPerson: currentUser?.name,
        vat: values?.vat,
        code: values?.code,
        name: values?.name,
        invStartNumber: Number(values?.invStartNumber),
        invEndNumber: Number(values?.invEndNumber),
        invTotalNumber: Number(values?.invTotalNumber),
        isLocked: values?.isLocked ? true : false,
        isGeneral: values?.isGeneral ? true : false,
        userNames,
        userUsernames,
      };
      let { data } = values?.id
        ? await updateSoThuTien(submitData, values?.id)
        : await createSoThuTien(submitData);
      if (CODE.SUCCESS === data?.code) {
        toast.success(
          values?.id
            ? RESPONSE_MESSAGE.UPDATE.SUCCESS
            : RESPONSE_MESSAGE.ADD.SUCCESS
        );
        onHide({
          openChonSoThu: !openDanhSachSoThu,
          openDanhSachSoThu: openDanhSachSoThu,
          openTaoMoiSoThu: false,
        });
        setOpenDanhSachSoThu(false);
      } else {
        toast.warning(data?.message || RESPONSE_MESSAGE.ERROR);
      }
    } catch (error) {
      toast.warning(RESPONSE_MESSAGE.ERROR);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={() =>
          onHide({
            openChonSoThu: !openDanhSachSoThu,
            openDanhSachSoThu: openDanhSachSoThu,
            openTaoMoiSoThu: false,
          })
        }
        size="xl"
      >
        <Formik<any>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, handleChange, setFieldValue }) => (
            <FormFormik>
              <Modal.Header closeButton className="py-5 header-modal">
                <Modal.Title>{`${
                  dataSoThu?.id ? "Cập nhật" : "Thêm mới"
                } sổ thu`}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="spaces px-20">
                <FormSoThu dataSoThu={dataSoThu} />
              </Modal.Body>
              <Modal.Footer className="flex flex-middle flex-end py-1 px-3">
                <Button className="btn-fill min-w-60px" type="submit">
                  Lưu
                </Button>
                <Button
                  className="btn-outline"
                  onClick={() => {
                    onHide({
                      openChonSoThu: !openDanhSachSoThu,
                      openDanhSachSoThu: openDanhSachSoThu,
                      openTaoMoiSoThu: false,
                    });
                    setOpenDanhSachSoThu(false);
                  }}
                >
                  Đóng
                </Button>
              </Modal.Footer>
            </FormFormik>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export { ModalTaoSoThu };

const FormSoThu: FC<any> = ({ dataSoThu }) => {
  const {
    values,
    setValues,
    setFieldValue,
    handleChange,
    resetForm,
    errors,
    touched,
  } = useFormikContext<any>();
  const { setIsLoading } = useContext(AppContext);
  let currentUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);
  const [shouldOpenChonNhanVienDialog, setShouldOpenChonNhanVienDialog] =
    useState(false);
  const [listNhanVien, setListNhanVien] = useState<IDsNhanVien[]>([]);
  const isUsed = dataSoThu?.invUsed > 0 ? true : false;

  const isValidValue = (num: any) =>
    num !== null && num !== undefined && !isNaN(num) && num !== "";
  const handleChangeInvNumber = (name: string, value: any) => {
    setFieldValue(name, value);
    let valueConverted = Number(value);
    let invTotalNumber = Number(values?.invTotalNumber);
    let invStartNumber = Number(values?.invStartNumber);
    switch (name) {
      case "invTotalNumber":
        if (isValidValue(value) && isValidValue(invStartNumber)) {
          setFieldValue("invEndNumber", invStartNumber + valueConverted - 1);
        } else {
          setFieldValue("invEndNumber", null);
        }
        break;
      case "invStartNumber":
        if (isValidValue(invTotalNumber) && isValidValue(value)) {
          setFieldValue("invEndNumber", valueConverted + invTotalNumber - 1);
        } else {
          setFieldValue("invEndNumber", null);
        }
        break;
      default:
        break;
    }
  };

  const handleShouldOpenChonNhanVienDialog = () => {
    setShouldOpenChonNhanVienDialog(true);
  };

  const updatePageData = async () => {
    setIsLoading(true);
    try {
      let { data } = await getThongTinSoThuTien(dataSoThu?.id);
      if (data?.code === CODE.SUCCESS) {
        let newValues = {
          ...data?.data,
          ledgerType: {
            id: data?.data?.ledgerTypeId,
            name: data?.data?.ledgerTypeName,
            code: data?.data?.ledgerTypeCode,
          },
          userNames: data?.data?.userNames
            ?.split(";")
            .map((itemUsername: any, index: any) => ({
              username: itemUsername,
            })),
          userUsernames: data?.data?.userUsernames
            ?.split(";")
            .map((itemUsername: any, index: any) => ({
              username: itemUsername,
            })),
        };
        setValues(newValues);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataSoThu?.id) {
      updatePageData();
    } else {
      resetForm();
    }
  }, []);

  return (
    <>
      <Row className="d-flex mb-2 align-items-center">
        <Col xs={2} className="spaces spaces px-4">
          <LabelRequired label="Ngày tạo" />
          <TextField
            type="date"
            name="createdDate"
            value={formatDateToYYYYMMDD(
              values?.createdDate ? values?.createdDate : new Date()
            )}
            disabled
          />
        </Col>
        <Col xs={3} className="spaces spaces px-4">
          <LabelRequired label="Người tạo" />
          <TextField name="user" value={currentUser?.name || ""} disabled />
        </Col>
        <Col xs={2} className="spaces spaces px-4">
          <LabelRequired label="Loại sổ" isRequired className="text-pri" />
          <AutocompleteV2
            options={[]}
            searchFunction={getSimpleCategory}
            searchObject={{ type: SIMPLE_CATEGORY_TYPE.LOAI_SO }}
            name="ledgerType"
            className="autocomplete-custom radius spaces width-100 "
            onChange={(data) => setFieldValue("ledgerType", data)}
            value={values?.ledgerType}
            isDisabled={isUsed}
            errors={errors?.ledgerType}
            touched={touched?.ledgerType}
          />
        </Col>
        <Col xs={1} className="d-flex align-items-center spaces px-4">
          <div className="spaces w-100">
            <LabelRequired label="VAT(%)" />
            <TextField
              name="vat"
              labelClassName="ps-2 min-w-90px fw-normal"
              value={values?.vat || ""}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col xs={2} className="d-flex align-items-center spaces px-4">
          <Form.Check
            className="spaces mt-25"
            type={"checkbox"}
            label={"Sổ tủ trực"}
          />
        </Col>
        <Col xs={2} className="d-flex align-items-center spaces px-4">
          <Form.Check
            className="spaces mt-25"
            type={"checkbox"}
            label={"Sổ dùng chung"}
            name="isGeneral"
            checked={values?.isGeneral}
            onChange={(e) => {
              setFieldValue("isGeneral", e.target.checked);
            }}
          />
        </Col>
      </Row>
      <Row className="d-flex mb-2 align-items-center">
        <Col xs={2} className="d-flex align-items-center spaces px-4">
          <div className="spaces w-100">
            <LabelRequired label="Mã sổ" isRequired />
            <TextField
              name="code"
              labelClassName="min-w-90px fw-normal"
              value={values?.code || ""}
              onChange={handleChange}
              disabled={isUsed}
              errors={errors?.code}
              touched={touched?.code}
              maxLength="10"
            />
          </div>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={8} className="d-flex align-items-center spaces px-4">
              <div className="spaces w-100">
                <LabelRequired label="Tên sổ" isRequired />
                <TextField
                  name="name"
                  labelClassName="ps-2 min-w-90px fw-normal"
                  value={values?.name || ""}
                  onChange={handleChange}
                  disabled={isUsed}
                  errors={errors?.name}
                  touched={touched?.name}
                />
              </div>
            </Col>

            <Col xs={4} className="d-flex align-items-center spaces px-4">
              <div className="spaces w-100">
                <LabelRequired label="Ký hiệu" />
                <TextField
                  name="symbol"
                  labelClassName="ps-2 min-w-90px fw-normal"
                  value={values?.symbol || ""}
                  onChange={handleChange}
                  disabled={isUsed}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={2} className="d-flex align-items-center spaces px-4">
          <div className="spaces w-100">
            <LabelRequired label="Tổng số phiếu" isRequired />
            <TextField
              name="invTotalNumber"
              labelClassName="min-w-90px fw-normal"
              value={
                isValidValue(values?.invTotalNumber)
                  ? values?.invTotalNumber
                  : ""
              }
              onChange={(e: any) =>
                handleChangeInvNumber(e.target.name, e.target.value)
              }
              disabled={isUsed}
              errors={errors?.invTotalNumber}
              touched={touched?.invTotalNumber}
            />
          </div>
        </Col>
        <Col xs={2} className="d-flex align-items-center spaces px-4">
          <div className="spaces w-100">
            <LabelRequired label="Số bắt đầu" isRequired />
            <TextField
              name="invStartNumber"
              labelClassName="min-w-90px fw-normal"
              value={
                isValidValue(values?.invStartNumber)
                  ? values?.invStartNumber
                  : ""
              }
              onChange={(e: any) =>
                handleChangeInvNumber(e.target.name, e.target.value)
              }
              disabled={isUsed}
              errors={errors?.invStartNumber}
              touched={touched?.invStartNumber}
            />
          </div>
        </Col>
        <Col xs={2} className="d-flex align-items-center spaces px-4">
          <div className="spaces w-100">
            <LabelRequired label="Số kết thúc" />
            <TextField
              name="invEndNumber"
              labelClassName="ps-2 min-w-90px fw-normal"
              value={
                isValidValue(values?.invEndNumber) ? values?.invEndNumber : ""
              }
              disabled
            />
          </div>
        </Col>
      </Row>
      <Row>
        {/* <Col xs={6} className="d-flex mb-2 align-items-center spaces px-4">
          <div className="spaces w-100">
            <Button
              className="bg-white px-0 ms-n3 mb-n1"
              onClick={handleShouldOpenChonNhanVienDialog}
            >
              <u className="fw-bold text-pri fs-13px px-0">Phân quyền thêm người sử dụng</u>
            </Button>
            <TextField
              disabled
              name="phanQuyen"
              as="textarea"
              // value={listNhanVien?.map(item => item?.tenNV)?.join("; ") || ""}
              labelClassName="min-w-90px fw-normal"
            />
          </div>
        </Col> */}
        <Col xs={6} className="d-flex mb-2 align-items-center spaces px-4">
          <div className="spaces w-100 mt-4">
            <LabelRequired label="Ghi chú" />
            <TextField
              name="notes"
              as="textarea"
              labelClassName="min-w-90px fw-normal"
              value={values?.notes || ""}
              onChange={handleChange}
            />
          </div>
        </Col>
        <Col xs={6} className="d-flex mb-2 align-items-center spaces px-4">
          <div className="spaces w-100 mt-4">
            <LabelRequired label="Người dùng" />
            <AutocompleteV2
              options={[]}
              searchFunction={getUsers}
              searchObject={{ authorities: KEY_AUTHORITIES.ACCESS.VIEN_PHI }}
              name="userUsernames"
              getOptionLabel={(option) => option.username}
              getOptionValue={(option) => option.username}
              className="autocomplete-custom radius spaces width-100 "
              onChange={(data) => setFieldValue("userUsernames", data)}
              value={values?.userUsernames}
              isDisabled={isUsed}
              isMulti={true}
              closeMenuOnSelect={!true}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="d-flex align-items-center spaces px-4">
          <div className="spaces w-100">
            <Form.Check // prettier-ignore
              className="spaces mt-10"
              type={"checkbox"}
              label={"Khóa"}
              name="isLocked"
              checked={values?.isLocked}
              onChange={(e) => {
                setFieldValue("isLocked", e.target.checked);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="border border-top-2 border-bottom-0 border-start-0 border-end-0 mt-2 pt-2">
        <Col className="text-pri fw-bold fs-4 mb-2">Hoá đơn điện tử</Col>
      </Row>
      <Row>
        <Col xs={3} className="spaces px-4">
          <div className="spaces w-100 mt-4">
            <LabelRequired label="Loại hóa đơn phát hành" />
            <TextField name="loaiHoaDon" />
          </div>
        </Col>

        <Col xs={3} className="spaces px-4">
          <div className="spaces w-100 mt-4">
            <LabelRequired label="Id của thông báo phát hành" />
            <TextField name="idCuaThongBao" />
          </div>
        </Col>
        <Col xs={3} className="spaces px-4">
          <div className="spaces w-100 mt-4">
            <LabelRequired label="Ký hiệu của thông báo phát hành" />
            <TextField name="kyHieuThongBao" />
          </div>
        </Col>

        <Col xs={3} className="spaces px-4">
          <div className="spaces w-100 mt-4">
            <LabelRequired label="Mẫu hóa đơn của thông báo phát hành" />
            <TextField name="mauHoaDon" />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={3} className="spaces px-4">
          <div className="spaces w-100 mt-4">
            <LabelRequired label="VATRateName" />
            <TextField name="vatRateName" />
          </div>
        </Col>
        <Col xs={3} className="spaces px-4">
          <Form.Check
            className="spaces mt-30"
            type={"checkbox"}
            label={"Chọn thông báo phát hành"}
          />
        </Col>
        <Col xs={3} className="spaces px-4">
          <Form.Check
            className="spaces mt-30"
            type={"checkbox"}
            label={"Không tự động tạo hóa đơn điện tử"}
          />
        </Col>
      </Row>

      <ModalChonNhanVien
        setListNhanVien={setListNhanVien}
        show={shouldOpenChonNhanVienDialog}
        handleCloseDialog={() => setShouldOpenChonNhanVienDialog(false)}
      />
    </>
  );
};
