import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  WfWorkflowsReq,
  WfWorkflowsRes,
} from "../../../workflows/models/WfWorkflows";
import {
  getWfWorkflows,
  updateWfWorkflows,
} from "../../../workflows/services/WfWorkflowsService";
import { CODE, RESPONSE_MESSAGE } from "../../../utils/Constant";
import { convertTrackerFieldResponseToObject } from "../../../workflows/utils";
import { toast } from "react-toastify";
import wfAxiosGenerator from "../../../workflows/utils/WfAxiosGenerator";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import SpinnerOverlay from "../../../component/loading/SpinnerOverlay";

interface ContextMenuProps {
  target?: { x: number; y: number } | null;
  className?: string;
  itemRow?: any;
  handleCloseMenu?: any;
  actionClick?: any;
  tracker: string;
  objectId: string;
  ButtonProps?: any;
  additionalFunc?: (
    currStatusId?: string | undefined,
    codeButton?: string | undefined
  ) => void;
  handleClick?: (props: WfAction) => void;
  additionalProps?: any;
  forceRender?: boolean;
  prefixFunction?: (
    currentStatusCode: string,
    wfCallbackFn: () => void | Promise<any>
  ) => void | Promise<any>;
  getTrackerField?: (fields: any) => void;
  hiddenWfButton?: boolean;
}

interface WfAction {
  componentName: string;
  nameReq: string;
  cancel?: () => void;
  submit: () => void;
}

const BASE_ENDPOINT =
  localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] ||
  process.env.REACT_APP_API_URL ||
  "";

const ContextMenuReturnResult: React.FC<ContextMenuProps> = (
  ContextMenuProps: ContextMenuProps
) => {
  const menuRefInner = useRef<any>(null);
  const [targetMenu, setTargetMenu] = useState<any>(ContextMenuProps?.target);

  const [statusCode, setStatusCode] = useState<any>();
  const [config, setConfig] = useState<any>();
  const [newStatusName, setNewStateName] = useState<string | undefined>();
  const [oldStatusName, setOldStatusName] = useState<string | undefined>();
  const [wfWorkflowsReqNew, setWfWorkflowsReqNew] = useState<WfWorkflowsReq>();
  const [wfWorkflowsReqOld, setWfWorkflowsReqOld] = useState<WfWorkflowsReq>();
  const [wfAxiosRequests, setWfAxiosRequests] = useState<any>();
  const [notification, setNotification] = useState<string>("");
  const [loadingButton, setLoadingButton] = useState<{
    new: boolean;
    old: boolean;
  }>({ new: false, old: false });
  const [loadingAdditionalButton, setLoadingAdditionalButton] = useState<{
    [key: string]: boolean;
  }>({});

  const showLoadingButton = (type: "new" | "old") =>
    setLoadingButton((current) => ({ ...current, [type]: true }));
  const hideLoadingButton = (type: "new" | "old") =>
    setLoadingButton((current) => ({ ...current, [type]: false }));
  const showLoadingAdditionalButton = (code: string) =>
    setLoadingAdditionalButton((current) => ({ ...current, [code]: true }));
  const hideLoadingAdditionalButton = (code: string) =>
    setLoadingAdditionalButton((current) => ({ ...current, [code]: false }));

  const updatePageData = useMemo(
    () => async () => {
      try {
        let { data } = await getWfWorkflows(
          ContextMenuProps.tracker,
          ContextMenuProps.objectId
        );
        if (data?.code === CODE.SUCCESS) {
          updateStates(data.data);
          ContextMenuProps.getTrackerField &&
            ContextMenuProps.getTrackerField(
              convertTrackerFieldResponseToObject(
                data.data.wfTrackerFieldRestDtoList
              )
            );
        }
      } catch (e) {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    },
    [ContextMenuProps.tracker, ContextMenuProps.objectId]
  );

  useEffect(() => {
    updatePageData();
  }, [
    ContextMenuProps.tracker,
    ContextMenuProps.objectId,
    ContextMenuProps?.forceRender,
    updatePageData,
  ]);

  const changeStatus = async (
    wfWorkflowsReq: WfWorkflowsReq | undefined,
    codeRequest?: string
  ) => {
    if (!wfWorkflowsReq) return;
    try {
      let { data } = await updateWfWorkflows(
        ContextMenuProps.tracker,
        wfWorkflowsReq
      );
      data?.data && updateStates(data?.data);
      if (ContextMenuProps.additionalFunc)
        ContextMenuProps.additionalFunc(codeRequest);
    } catch (error) {
      console.error(error);
      toast.error(RESPONSE_MESSAGE.ERROR);
    }
  };
  const updateStates = (wfWorkflowsRes: WfWorkflowsRes) => {
    wfWorkflowsRes.additionalConfiguration &&
      setConfig(JSON.parse(wfWorkflowsRes.additionalConfiguration));
    setStatusCode({
      old: wfWorkflowsRes.oldStatusCode,
      curr: wfWorkflowsRes.currStatusCode,
      new: wfWorkflowsRes.newStatusCode,
    });
    setNewStateName(wfWorkflowsRes.newStatusName);
    setOldStatusName(wfWorkflowsRes.oldStatusName);
    setWfWorkflowsReqNew({
      objectId: ContextMenuProps.objectId,
      status: wfWorkflowsRes.newStatusId,
    });
    setWfWorkflowsReqOld({
      objectId: ContextMenuProps.objectId,
      status: wfWorkflowsRes.oldStatusId,
    });
    setWfAxiosRequests(
      wfAxiosGenerator(BASE_ENDPOINT, wfWorkflowsRes.additionalConfiguration)
    );
  };

  const handleButtonClick = async (additionalButton: any) => {
    if (loadingAdditionalButton?.[additionalButton.code]) return;
    showLoadingAdditionalButton(additionalButton.code);

    try {
      const submit = () => {
        additionalButton.handleClick(ContextMenuProps);
        ContextMenuProps.additionalFunc?.(
          statusCode.curr,
          additionalButton.code
        );
        hideLoadingAdditionalButton(additionalButton.code);
      };

      if (additionalButton?.componentName && ContextMenuProps.handleClick) {
        ContextMenuProps.handleClick({
          componentName: additionalButton.componentName,
          nameReq: additionalButton.name,
          submit: submit,
          cancel: () => hideLoadingAdditionalButton(additionalButton.code),
        });
      } else {
        submit();
      }
    } catch (error) {
      console.error(error);
      toast.error("Đã có lỗi xảy ra!");
    } finally {
      hideLoadingAdditionalButton(additionalButton.code);
    }
  };

  const handleReq = async (
    statusReq: WfWorkflowsReq | undefined,
    codeReq: string,
    nameReq: string,
    componentName: string,
    type: "new" | "old"
  ) => {
    if (loadingButton[type]) return;
    showLoadingButton(type);
    try {
      const actions = wfAxiosRequests?.actions?.[codeReq];
      const submit = async () => {
        if (actions) {
          for (const func of actions) {
            let data = await func(ContextMenuProps);
            if (data?.data?.data?.[0]?.errorMessage) {
              setNotification(data?.data?.data[0]?.errorMessage || "");
              return data;
            }
          }
        }

        if (ContextMenuProps.prefixFunction) {
          ContextMenuProps.prefixFunction(statusCode.curr, async () => {
            await changeStatus(statusReq, codeReq);
            hideLoadingButton(type);
          });
          return;
        }

        await changeStatus(statusReq, codeReq);
        hideLoadingButton(type);
      };

      if (componentName && ContextMenuProps.handleClick) {
        ContextMenuProps.handleClick({
          componentName: componentName,
          nameReq: nameReq,
          submit: submit,
          cancel: () => hideLoadingButton(type),
        });
      } else {
        await submit();
      }
    } catch (error) {
      console.error(error);
      toast.error("Đã có lỗi xảy ra!");
      hideLoadingButton(type);
    } finally {
      hideLoadingButton(type);
    }
  };
  const closeMenu = useCallback(
    (event: MouseEvent) => {
      if (targetMenu && !menuRefInner.current?.contains(event.target as Node)) {
        ContextMenuProps?.handleCloseMenu?.();
      }
    },
    [ContextMenuProps?.handleCloseMenu, targetMenu]
  );

  useEffect(() => {
    document.addEventListener("click", closeMenu);

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [closeMenu]);
  const [targetMouse, settargetMouse] = useState<any>(null);
  const renderMenu = (targetMenu: any) => {
    let leftValue = 0;
    if (
      targetMenu?.x + menuRefInner?.current?.clientWidth * 2 >
      window.innerWidth
    ) {
      leftValue = targetMouse?.left - menuRefInner?.current?.clientWidth - 2;
    } else {
      leftValue = targetMouse?.left + menuRefInner?.current?.clientWidth + 2;
    }
    return (
      <ul
        ref={menuRefInner}
        className="m-0 p-0 drop-list-container spaces W-200"
      >
        {!ContextMenuProps?.hiddenWfButton && (
          <>
            {wfAxiosRequests &&
              wfAxiosRequests.axiosRequests &&
              ContextMenuProps.objectId &&
              wfAxiosRequests.axiosRequests.map((additionalButton: any) => (
                <li
                  className={`position-relative p-1 drop-list-item d-flex justify-content-between align-items-center border-bottom ${
                    loadingAdditionalButton?.[additionalButton.code]
                      ? "cursor-wait"
                      : ""
                  }`}
                  key={additionalButton.code}
                  onClick={() => handleButtonClick(additionalButton)}
                >
                  {loadingAdditionalButton?.[additionalButton.code] && (
                    <SpinnerOverlay />
                  )}
                  {additionalButton.name}
                </li>
              ))}
            {oldStatusName && ContextMenuProps.objectId && (
              <li
                id="wf-old-status-button"
                onClick={() =>
                  handleReq(
                    wfWorkflowsReqOld,
                    statusCode.old,
                    oldStatusName,
                    config?.componentNameOldReq,
                    "old"
                  )
                }
                className={`position-relative p-1 drop-list-item d-flex justify-content-between align-items-center border-bottom ${
                  loadingButton.new ? "cursor-wait" : ""
                }`}
              >
                {loadingButton.old && <SpinnerOverlay />}
                <span className="fs-4 min-w-15px text-center">
                  <i
                    className={config?.additionalAction?.find(
                      (item: any) => item?.code === statusCode.old
                    )?.iconClassName}
                  />
                </span>
                <div className="d-flex justify-content-between align-items-center w-100 ps-2">
                  <span>
                    {config?.oldStatusName
                      ? config?.oldStatusName
                      : oldStatusName}
                  </span>{" "}
                </div>
              </li>
            )}
            {newStatusName && ContextMenuProps.objectId && (
              <li
                className={`position-relative p-1 drop-list-item d-flex justify-content-between align-items-center border-bottom ${
                  loadingButton.new ? "cursor-wait" : ""
                }`}
                id="wf-new-status-button"
                onClick={() =>
                  handleReq(
                    wfWorkflowsReqNew,
                    statusCode.new,
                    newStatusName,
                    config?.componentNameNewReq,
                    "new"
                  )
                }
              >
                {loadingButton.new && <SpinnerOverlay />}
                <span className="fs-4 min-w-15px text-center">
                  <i
                    className={`fs-16 ${config?.additionalAction?.find(
                      (item: any) => item?.code === statusCode.new
                    )?.iconClassName}`}
                  />
                </span>
                <div className="d-flex justify-content-between align-items-center w-100 ps-2">
                  <span>
                    {config?.newStatusName
                      ? config?.newStatusName
                      : newStatusName}
                  </span>
                </div>
              </li>
            )}
          </>
        )}
        {ContextMenuProps?.ButtonProps}
      </ul>
    );
  };

  useEffect(() => {
    if (ContextMenuProps?.target) {
      const menuWidth = menuRefInner.current?.clientWidth;
      const menuHeight = menuRefInner.current?.clientHeight;
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      let newTarget = { ...ContextMenuProps?.target };

      if (ContextMenuProps?.target.x + menuWidth > screenWidth) {
        newTarget = { ...newTarget, x: screenWidth - menuWidth - 50 };
      }

      if (ContextMenuProps?.target.y + menuHeight > screenHeight) {
        newTarget = { ...newTarget, y: screenHeight - menuHeight - 50 };
      }

      setTargetMenu(newTarget);
    }
  }, [ContextMenuProps?.target]);

  return (
    <div
      style={{ top: targetMenu?.y ?? 0, left: targetMenu?.x + 20 ?? 0 }}
      className={`context-menu position-fixed ${ContextMenuProps?.className} z-index-1060 spaces`}
    >
      <div className="border">{targetMenu && renderMenu(targetMenu)}</div>
    </div>
  );
};

export default ContextMenuReturnResult;
