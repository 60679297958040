import { Column } from 'react-table';
import { TableCustomCell } from '../../component/table/components/TableCustomCell';
import { TableCustomHeader } from '../../component/table/components/TableCustomHeader';
import { IBenhNhanModel } from '../../phan-he-xet-nghiem/models/DanhSachBenhNhanModels';
import { formatTrangThaiBenhNhan } from '../../utils/FormatUtils';
import { ID_DANG_KY_KHAM, UU_TIEN } from '../../utils/Constant';


export const columnsDSChiDinh = [
    { title: "Mã dịch vụ", field: "maDichVu", className: "spaces fs-8 w-10" },
    { title: "Tên dịch vụ", field: "tenDichVu", className: "spaces fs-8 w-20" },
    { title: "Phương pháp", field: "phuongPhap", className: "spaces fs-8 w-20" },
    { title: "Số lượng", field: "soLuong", className: "spaces fs-8 w-10" },
    { title: "Trạng thái", field: "trangThai", className: "spaces fs-8 w-20" },
    { title: "Ghi chú", field: "ghiChu", className: "spaces fs-8 w-20" },
]

export const ColumnsDsBenhNhanCDHA: ReadonlyArray<Column<IBenhNhanModel>> = [
    {
        Header: (props) => (
            <TableCustomHeader<IBenhNhanModel>
                tableProps={props}
                title={"TT"}
                className="text-light text-center min-w-30px"
            />
        ),
        id: "TT",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={formatTrangThaiBenhNhan(props?.data[props?.row?.index]?.color)}
            />
        ),
    },
    // {
    //     Header: (props) => (
    //         <TableCustomHeader<IBenhNhanModel>
    //             tableProps={props}
    //             title={"STT"}
    //             className="text-center text-light min-w-30px"
    //         />
    //     ),
    //     id: "STT",
    //     Cell: ({ ...props }) => (
    //         <TableCustomCell
    //             className="text-center"
    //             data={+props.row.id + 1}
    //         />
    //     ),
    // },
    {
        Header: (props) => (
            <TableCustomHeader<IBenhNhanModel>
                tableProps={props}
                title={"STT"}
                className="text-center text-light min-w-30px"
            />
        ),
        id: "STT",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={+props.row.id + 1}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<IBenhNhanModel>
                tableProps={props}
                title={"Tên bệnh nhân"}
                className="text-center text-light min-w-300px"
            />
        ),
        id: "tenKH",
        Cell: ({ ...props }) => (
            <div className="text-system">
                <div className="flex m-0">
                    <span className={`text-uppercase fs-14px ${props?.data[props?.row?.index]?.visitTypeId === ID_DANG_KY_KHAM.CAP_CUU ? "color-error" : ""}`}>{props?.data[props?.row?.index]?.patientName}</span>
                </div>
                <div className="flex m-0">
                    <span className="text-uppercase fw-semibold fs-14px">{props?.data[props?.row?.index]?.patientCode}</span>
                    <span className="px-1 fs-14px"> - </span>
                    <span className='fs-14px'>
                        {props?.data[props?.row?.index]?.visitObjectTypeName}
                    </span>
                    <span className="px-1 fs-14px"> - </span>
                    <span className='fs-14px'>
                        {props?.data[props?.row?.index]?.gender}
                    </span>
                </div>
            </div>
        ),
    },
    // {
    //     Header: (props) => (
    //         <TableCustomHeader<IBenhNhanModel>
    //             tableProps={props}
    //             title={"Mã phiếu"}
    //             className="text-center text-light min-w-60px"
    //         />
    //     ),
    //     id: "Mã phiếu",
    //     Cell: ({ ...props }) => (
    //         <TableCustomCell
    //             className="text-center fs-14"
    //             data={props?.data[props?.row?.index]?.code}
    //         />
    //     ),
    // },
];

export const columns = [
    {
        name: "TT",
        field: "",
        headerStyle: {
            maxWidth: "30px",
        },
        render: (rowData: any, index: number) => formatTrangThaiBenhNhan(rowData?.color),
    },
    {
        name: "STT",
        field: "",
        headerStyle: {
            maxWidth: "40px",
        },
        cellStyle: {
            textAlign: "center",
        },
        render: (rowData: any, index: number) => <span className="text-system">{rowData?.ordinalNumber}</span>
    },
    {
        name: "Tên bệnh nhân",
        field: "",
        headerStyle: {
            minWidth: "300px",
        },
        render: (rowData: any, index: number) => {
            let display = JSON.parse(rowData?.display) || {}
            return (
                <div className="text-system">
                    <div className="flex m-0">
                        <span className={`text-uppercase fs-14px ${rowData?.visitTypeId === ID_DANG_KY_KHAM.CAP_CUU ? "color-error" : ""}`}>{rowData?.patientName}</span>
                        {<span style={display?.style}>&nbsp;{display?.text ? `(${display?.text})` : ""}</span>}
                        {rowData?.visitSortWeight === UU_TIEN.TRUE ? <span className="text-danger">&nbsp;(Ưu tiên)</span> : ""}
                    </div>
                    <div className="flex m-0">
                        <span className="text-uppercase fw-semibold fs-14px">{rowData?.patientCode}</span>
                        <span className="px-1 fs-14px"> - </span>
                        <span className='fs-14px'>
                            {rowData?.visitObjectTypeName}
                        </span>
                        <span className="px-1 fs-14px"> - </span>
                        <span className='fs-14px'>
                            {rowData?.gender}
                        </span>
                    </div>
                </div>
            );
        },
    },
];