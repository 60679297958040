import { useFormikContext } from "formik";
import React, { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomTabMenu from "../../../component/CustomTabMenu";
import SinhHieu from "../../../phan-he-kham-benh/components/tab-kham-benh/SinhHieu";
import { iSinhHieu } from "../../../phan-he-kham-benh/models/ThongTinKhamBenhModel";
import { PhanHeTiepNhanContext } from "../../PhanHeTiepNhan";
import { benhNhanProps } from "../../tab-tiep-nhan/TiepNhan";
import TabLichSu from "./tab-lich-su-kham/TabLichSu";
import TabGiaDinh from "./tabGiaDinh/TabGiaDinh";
import TabThongTinPhongKham from "./tabThongTinPhongKham/TabThongTinPhongKham";
import { getValueTextFormFieldConfig } from "../../services/TiepNhanServices";
import DynamicConentTab from "./dynamic-content-tab";

const TYPE_ADDITIONAL_ELEMENT = {
  TAB: "tab",
};

const TTKhamBenhV2: FC<benhNhanProps> = React.memo(
  () => {
    const {values, setFieldValue} = useFormikContext<any>();
    const { thongTinBenhNhan } = useContext(PhanHeTiepNhanContext);
    const [listAdditionalTab, setListAdditionalTab] = useState<any[]>([]);
    
    const handleChangeSinhHieu = (name: string, value: any) => {
     try {
       setFieldValue("vitalSigns", {
         ...values?.vitalSigns,
         [name]: value,
       });
     } catch (e) {
      console.error(e);
     }
    };

    const listFixedTab = [
      {
        title: 'TT phòng khám',
        component: <TabThongTinPhongKham/>
      },
      // {
      //   title: 'DS lịch hẹn',
      //   component: <TabDSDangKy/>
      // },
      {
        title: 'LS khám bệnh',
        component: <TabLichSu />
      },
      // {
      //   title: 'Danh sách chờ gọi',
      //   component: <DanhSachChoGoiV2 />
      // },
      {
        title: 'Sinh hiệu',
        component: <SinhHieu
          sinhHieu={values?.vitalSigns as iSinhHieu}
          setSinhHieu={handleChangeSinhHieu}
          className="spaces pt-10 px-10 h-calc-vh-510"
          disabled={thongTinBenhNhan?.isExamined}
          isFromTiepDon={true}
        />
      },
      // {
      //   title: 'Zalo',
      //   component: <TabZalo/>
      // },
      // {
      //   title: 'Xác thực',
      //   component: <TabXacThuc />
      // },
      {
        title: 'Gia đình',
        component: <TabGiaDinh />
      },
      // {
      //   title: 'Chỉ định dịch vụ',
      //   component: <ChiDinhDVTiepDon />
      // },
      // {
      //   title: 'Phiếu thu',
      //   component: <TabPhieuThu />
      // },
      // {
      //   title: 'Danh sách gộp PCR',
      //   component: <TabDanhSachGopPCR />
      // },
    ];

    const handleGetListTab = async () => {
      try {
        let tabs: any[] = [];
        let { data } = await getValueTextFormFieldConfig("ADMISSION_ADDITIONAL_FORM");
        data?.detailInfo?.forEach((item: any) => {
          if (item?.type === TYPE_ADDITIONAL_ELEMENT.TAB) {
            tabs.push({
              title: item?.name,
              component: typeof item?.form === "string" ?  <DynamicConentTab formName={item?.form} /> : <></>
            });
          }
        });
        setListAdditionalTab(tabs);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      handleGetListTab();
    }, []);

    return (
      <div className="reception-list__container spaces py-0 mt-8 bg-white">
        <Row className="h-100">
          <Col xs={12} className="h-100 spaces px-0">
            <CustomTabMenu danhsachTabs={[...listFixedTab, ...listAdditionalTab]} childrenTab={true}/>
          </Col>
        </Row>
      </div>
    );
  }
);
export default TTKhamBenhV2;
