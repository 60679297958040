// @ts-nocheck
import { formatDateTime, formatDateToDDMMYYYY, formatTrangThaiBenhNhan } from '../../../../utils/FormatUtils'

const LichSuKhamColumn = [
	{
		name: '',
		field: '',
		cellStyle: {
			paddingLeft: '10px',
			textAlign: 'start',
		},
		render: (rowData: any) => formatTrangThaiBenhNhan(rowData?.color),
	},
	{
		name: 'Ngày vào',
		field: '',
		cellStyle: {
			textAlign: 'start',
			minWidth: '150px',
		},
		render: (rowData: any) =>
			rowData?.orderStartedDate ? formatDateTime(rowData?.orderStartedDate) : '',
	},
	{
		name: 'Khoa',
		field: 'department',
		cellStyle: {
			textAlign: 'start',
			minWidth: '250px',
		},
	},
	{
		name: 'Phòng khám',
		field: 'clinic',
		cellStyle: {
			textAlign: 'start',
			minWidth: '250px',
		},
	},
	{
		name: 'Bác sĩ',
		field: 'doctor',
		cellStyle: {
			textAlign: 'start',
			minWidth: '200px',
		},
	},
	{
		name: 'Thẻ BHYT',
		field: 'insuranceCode',
		cellStyle: {
			textAlign: 'start',
			minWidth: '200px',
		},
	},
	{
		name: 'Ngày sinh',
		field: 'birthDate',
		cellStyle: {
			textAlign: 'center',
			minWidth: '120px',
		},
	},
	{
		name: 'Ngày ra',
		field: '',
		cellStyle: {
			textAlign: 'center',
			minWidth: '150px',
		},
		render: (rowData: any) =>
			rowData?.orderStoppedDate ? formatDateTime(rowData?.orderStoppedDate) : '',
	},
	{
		name: 'Xử trí',
		field: 'status',
		cellStyle: {
			textAlign: 'start',
			minWidth: '200px',
		},
	},
	{
		name: 'Thông tin khác',
		field: 'descriptions',
		cellStyle: {
			textAlign: 'start',
			minWidth: '200px',
		},
	},
];

export { LichSuKhamColumn }

