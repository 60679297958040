import { Formik } from "formik";
import { FocusEvent, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AutocompleteV2 from "../../../component/AutocompleteObjectV2";
import { TableCollapseCustom } from "../../../component/table/table-collapse/TableCollapseCustom";
import TextField from "../../../component/TextField";
import { CODE, STATUS_CANCEL } from "../../../utils/Constant";
import WfContextMenu from "../../../workflows/components/WfContextMenu";
import { WfConstTracker } from "../../../workflows/Constant";
import { convertListDichVuXetNgiem, genderClassName } from "../../common";
import { DATA_TYPE_XN, WF_STEP } from "../../const/constants";
import { PhanHeXetNghiemContext } from "../../context/PhanHeXetNghiemContext";
import {
  getPhieuXetNghemInfo,
  inKetQuaDichVu,
  saveObsValue,
} from "../../service/tiepNhan";
import DialogChuyenDV from "./menu-dich-vu/DialogChuyenDV";
import ContextMenu from "./ContextMenuReturnResult";
import { getWfWorkflows } from "../../../workflows/services/WfWorkflowsService";
import WfButton from "../../../workflows/components/WfButton";
import { Button } from "react-bootstrap";
import FormInPhieuDialog from "../../../component/button-in-phieu/components/PrintDialog";

const BangLayMauBenhPham = (props: any) => {
  const { benhNhanInfo, phieuXetNghiemInfo, setPhieuXetNghiemInfo } =
    useContext(PhanHeXetNghiemContext);
  const {
    actionClick,
    rowSelect,
    setRowSelect,
    handleGetPhieuXetNghiem,
    handleGetPreviousResult,
  } = props;
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [openChuyenDV, setOpenChuyenDV] = useState(false);
  const [dataKQ, setDataKQ] = useState<any>([]);
  const [fieldLoading, setFieldLoading] = useState<any>({});
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [configurationContextMenu, setConfigurationContextMenu] =
    useState<any>(null);
  const [currStatusCode, setCurrentStatusCode] = useState<any>(null);
  const [openPrintDialog, setOpenPrintDialog] = useState(false);
  const [itemService, setItemService] = useState<any>(null);
  useEffect(() => {
    setDataKQ(phieuXetNghiemInfo?.dsXetNghiem || []);
  }, [phieuXetNghiemInfo]);

  const handleChuyenPhongDV = (phong: any) => {
    setOpenChuyenDV(false);
    for (let i = 0; i < dataKQ.length; i++) {
      if (dataKQ[i].tenXetNghiem === rowSelect?.original?.tenXetNghiem) {
        dataKQ[i].tenXetNghiem = phong?.[0]?.original?.tenPhong;
        break;
      }
    }
    setDataKQ(dataKQ);
    toast.success("Chuyển phòng dịch vụ thành công");
  };

  const handleGetDsDichVu = async () => {
    const { data: dataXN } = await getPhieuXetNghemInfo(
      benhNhanInfo?.testGroupId
    );
    if (CODE.SUCCESS === dataXN?.code) {
      const thongTinPhieu = dataXN?.data;
      const dsXetNghiem = convertListDichVuXetNgiem(thongTinPhieu.ordersList);
      setPhieuXetNghiemInfo({
        thongTinPhieu,
        dsXetNghiem,
      });
    }
  };
  const handleSaveObs = async (rowData: any, value: any) => {
    try {
      if (value !== rowData.obs?.value) {
        setFieldLoading({ [rowData.id]: true });
        const submitData = {
          personId: benhNhanInfo?.patient?.personId,
          termId: rowData.termId || rowData.id,
          orderId: rowData?.orderId,
          value: value.id || value,
        };
        await saveObsValue(submitData);
        await handleGetDsDichVu();
      }
    } catch (error) {
      console.error(error);
      toast.warning("Lỗi lưu kết quả, vui lòng nhập lại!");
    } finally {
      setFieldLoading({});
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectedRowIndex && !event?.target?.closest(".textfield-container")) {
        setSelectedRowIndex(null);
        setItemService(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedRowIndex]);

  const generateInput = (rowData: any) => {
    let dataType = rowData?.term?.datatypeId || rowData?.datatypeId;
    let isTestStart =
      phieuXetNghiemInfo.thongTinPhieu.state.currStatusCode ===
      WF_STEP.TEST_START;
    switch (dataType) {
      case DATA_TYPE_XN.TEXT:
        return (
          <div className="textfield-container d-flex align-items-center w-100">
            <TextField
              className="w-100"
              inputClassName="w-100"
              type="text"
              name="obsValue"
              defaultValue={rowData?.obs?.value || ""}
              disabled={!isTestStart}
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                if (
                  e?.target?.value !== rowData?.obs?.value &&
                  (e?.target?.value || rowData?.obs?.value)
                ) {
                  handleSaveObs(rowData, e?.target?.value);
                  setSelectedRowIndex(null);
                }
              }}
              autoFocus={true}
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      case DATA_TYPE_XN.DATETIME:
      case DATA_TYPE_XN.DATE:
        return (
          <div className="d-flex align-items-center">
            <input
              type={
                dataType === DATA_TYPE_XN.DATETIME ? "datetime-local" : "date"
              }
              className="w-100"
              defaultValue={rowData?.obs?.value || ""}
              disabled={!isTestStart}
              onBlur={(e) => {
                handleSaveObs(rowData, e.target.value);
              }}
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      case DATA_TYPE_XN.BOOLEAN:
        return (
          <div className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              className="form-check-input"
              defaultChecked={rowData?.obs?.value === "true"}
              disabled={!isTestStart}
              onChange={(e) => {
                handleSaveObs(rowData, e.target.checked);
              }}
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      case DATA_TYPE_XN.NUMERIC:
        return (
          <div className="textfield-container d-flex align-items-center w-100">
            <TextField
              className="w-100"
              inputClassName="w-100"
              type="number"
              name="obsValue"
              defaultValue={rowData?.obs?.value || ""}
              disabled={!isTestStart}
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                if (
                  +e?.target?.value !== +rowData?.obs?.value &&
                  (e?.target?.value || rowData?.obs?.value)
                ) {
                  handleSaveObs(rowData, e?.target?.value);
                  setSelectedRowIndex(null);
                }
              }}
              autoFocus={true}
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      case DATA_TYPE_XN.CODE:
      case DATA_TYPE_XN.PARENT_CODE:
        return (
          <div className="textfield-container d-flex align-items-center">
            <AutocompleteV2
              options={rowData?.answers || []}
              value={rowData?.answers.find(
                (item: any) => item.name === rowData?.obs?.value
              )}
              name="obsValue"
              valueField="name"
              onChange={(selectedOption) => {
                handleSaveObs(rowData, selectedOption);
                setSelectedRowIndex(null);
              }}
              isDisabled={!isTestStart}
              className="autocomplete-custom-tiep-nhan radius spaces width-100 h-25"
            />
            {fieldLoading[rowData.id] && (
              <span className="loading-spinner"></span>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setSelectedRowIndex(null);
  }, [benhNhanInfo?.testGroupId]);

  const columnThongTinXetNghiem = [
    {
      title: "Mã DV",
      field: "code",
      className: "spaces width-11 text-center",
      render: (rowData: any) => {
        return (
          <div
            className={`${genderClassName(rowData)} text-align-left`}
            style={{ color: rowData?.statusColor }}
          >
            {rowData?.code}
          </div>
        );
      },
    },
    {
      title: "Tên xét nghiệm",
      field: "name",
      className: "spaces width-30",
      render: (rowData: any) => {
        return (
          <div
            className={`${genderClassName(rowData)}`}
            style={{ color: rowData?.statusColor }}
          >
            {rowData?.name}
          </div>
        );
      },
    },
    {
      title: "Kết Quả",
      field: "obs.value",
      className: "spaces width-13 text-center",
      render: (rowData: any, listIndex: any[], colIndex: number) => {
        return (
          <Formik initialValues={{}} onSubmit={() => {}}>
            {selectedRowIndex === rowData?.id && benhNhanInfo?.isPerform && !rowData?.subs?.length ? (
              generateInput(rowData)
            ) : (
              <div
                className={`h-31 d-flex align-items-center justify-content-center ${genderClassName(
                  rowData
                )}`}
                style={{ color: rowData?.statusColor }}
                onClick={async () => {
                  await handleTypeValue(rowData);
                }}
              >
                {rowData?.obs?.value}
              </div>
            )}
          </Formik>
        );
      },
    },
    {
      title: "KQ cũ",
      field: "obs.value",
      className: "spaces width-11 text-center",
      render: (rowData: any, listIndex: any[], colIndex: number) => {
        let res: any = props?.listPreviousResult?.find(
          (item: any) => item?.id === rowData?.id
        );
        return (
          <div
            className={`${genderClassName(rowData)}`}
            style={{ color: rowData?.statusColor }}
          >
            {res?.value || ""}
          </div>
        );
      },
    },
    {
      title: "",
      field: "HL",
      className: "spaces width-4 text-center",
      render: (rowData: any) => {
        const validValue = (value: any) => {
          return value || value === 0;
        };
        let isText = rowData?.datatypeId === DATA_TYPE_XN.TEXT;
        let lowNormal = rowData?.numeric?.lowNormal;
        let hiNormal = rowData?.numeric?.hiNormal;
        let value = rowData?.obs?.value;
        let result = "";
        if (validValue(value) && validValue(hiNormal) && value > hiNormal) {
          result = "H";
        } else if (
          validValue(value) &&
          validValue(lowNormal) &&
          value < lowNormal
        ) {
          result = "L";
        }
        return !isText ? (
          <div
            className={`${genderClassName(rowData)}`}
            style={{ color: rowData?.statusColor }}
          >
            {result}
          </div>
        ) : (
          ""
        );
      },
    },
    {
      title: "Đơn vị",
      field: "donVi",
      className: "spaces width-6 text-center",
      render: (rowData: any) => {
        return (
          <div
            className={`${genderClassName(rowData)}`}
            style={{ color: rowData?.statusColor }}
          >
            {rowData?.numeric?.units}
          </div>
        );
      },
    },
    {
      title: "GTBT",
      field: "gtbt",
      className: "spaces width-6 text-center",
      render: (rowData: any) => {
        let isText = rowData?.datatypeId === DATA_TYPE_XN.TEXT;
        const validValue = (value: any) => {
          return value || value === 0;
        };
        let result = "";
        let lowNormal = rowData?.numeric?.lowNormal;
        let hiNormal = rowData?.numeric?.hiNormal;
        if (isText) {
          result = rowData?.numeric?.label || "";
        } else if (validValue(lowNormal) && validValue(hiNormal)) {
          result =
            lowNormal === hiNormal ? lowNormal : lowNormal + " - " + hiNormal;
        } else if (validValue(lowNormal) && !validValue(hiNormal)) {
          result = "> " + lowNormal;
        } else if (!validValue(lowNormal) && validValue(hiNormal)) {
          result = "< " + hiNormal;
        }
        return (
          <div
            className={`${genderClassName(rowData)}`}
            style={{ color: rowData?.statusColor }}
          >
            {result || ""}
          </div>
        );
      },
    },
    { title: "Loại bệnh phẩm", field: "type", className: "spaces width-10" },
    { title: "Ghi chú", field: "description", className: "spaces width-10" },
  ];

  const handleClearContext = () => {
    setConfigurationContextMenu(null);
    setItemService(null);
    setCurrentStatusCode(null);
    setContextMenu?.(null);
  };

  const handleTypeValue = async (row: any) => {
    let isService = !row?.attributes?.isComponent;
    try {
      if (isService) {
        let tracker = WfConstTracker.TEST_ORDER;
        let objectId = row?.orderId;
        let { data } = await getWfWorkflows(tracker, objectId);
        if (
          data?.code === CODE.SUCCESS &&
          data?.data?.currStatusCode !== WF_STEP.TEST_ORDER_RESULT
        ) {
          setSelectedRowIndex(row?.id);
        } else {
          setSelectedRowIndex(null);
        }
      } else {
        // Chỉ số con
        let tracker = WfConstTracker.TEST_ORDER;
        let objectId = row?.orderId;

        // Kiểm tra th cha đã trả kết quả chưa
        let { data } = await getWfWorkflows(tracker, objectId);
        if (
          data?.code === CODE.SUCCESS &&
          data?.data?.currStatusCode !== WF_STEP.TEST_ORDER_RESULT
        ) {
          let tracker = WfConstTracker.TEST_OBS;
          let objectId = row?.obs?.id;

          let { data } = await getWfWorkflows(tracker, objectId);
          if (
            data?.code === CODE.SUCCESS &&
            data?.data?.currStatusCode === WF_STEP.TEST_OBS_RESULT
          ) {
            setSelectedRowIndex(null);
          } else {
            setSelectedRowIndex(row?.id);
          }
        } else {
          setSelectedRowIndex(null);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFieldLoading({});
    }
  };

  const checkReturnedFullResultsObs = (service: any) => {
    let isEnough = true;
    if (service && service?.subs?.length > 0) {
      for (let i = 0; i <= service?.subs?.length - 1; i++) {
        if (!service?.subs[i]?.obs?.id) return !isEnough;
      }
    }
    return isEnough;
  };

  const handleContextMenu = async (e: any, row: any) => {
    e.preventDefault();
    try {
      setRowSelect(row);
      if (benhNhanInfo?.isTestRecieveSample) {
        if (row?.additionalConfiguration) {
          setConfigurationContextMenu(row?.additionalConfiguration);
          setContextMenu({ x: e.clientX, y: e.clientY });
        } else {
          setConfigurationContextMenu(null);
        }
      } else {
        let isService = !row?.attributes?.isComponent && row?.id;
        if (isService) {
          let tracker = WfConstTracker.TEST_ORDER;
          let objectId = row?.orderId;
          if (row?.subs?.length) {
            let { data } = await getWfWorkflows(tracker, objectId);
            if (data?.code === CODE.SUCCESS) {
              setItemService(data?.data);
              setConfigurationContextMenu(data?.data?.additionalConfiguration);
              setContextMenu({ x: e.clientX, y: e.clientY });
            } else {
              handleClearContext();
            }
          } 
          else if(!row?.subs?.length && row?.obs?.id) {
            let { data } = await getWfWorkflows(tracker, objectId);
            if (data?.code === CODE.SUCCESS) {
              setItemService(data?.data);
              setConfigurationContextMenu(data?.data?.additionalConfiguration);
              setContextMenu({ x: e.clientX, y: e.clientY });
            } else {
              handleClearContext();
            }
          }
          else {
            handleClearContext();
          }
        } else {
          let tracker = WfConstTracker.TEST_ORDER;
          let objectId = row?.orderId;

          let { data } = await getWfWorkflows(tracker, objectId);
          if (
            data?.code === CODE.SUCCESS &&
            data?.data?.currStatusCode !== WF_STEP.TEST_ORDER_RESULT
          ) {
            if (row?.obs?.id) {
              let tracker = WfConstTracker.TEST_OBS;
              let objectId = row?.obs?.id;

              let { data } = await getWfWorkflows(tracker, objectId);
              if (data?.code === CODE.SUCCESS) {
                setItemService(data?.data);
                setConfigurationContextMenu(
                  data?.data?.additionalConfiguration
                );
                setContextMenu({ x: e.clientX, y: e.clientY });
              } else {
                handleClearContext();
              }
            } else {
              handleClearContext();
            }
          } else {
            handleClearContext();
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFieldLoading({});
    }
  };

  const actionChanged = (currStatusCodeUpdate: string | undefined) => {
    if (currStatusCodeUpdate !== currStatusCode) {
      setCurrentStatusCode(currStatusCodeUpdate);
      switch (currStatusCodeUpdate) {
        case WF_STEP.TEST_OBS_RESULT:
        case WF_STEP.TEST_ORDER_RESULT:
          toast.success("Trả kết quả thành công");
          break;
        case WF_STEP.TEST_OBS_START:
        case WF_STEP.TEST_ORDER_START:
          toast.success("Hủy trả kết quả thành công");
          break;
      }
    }
    handleGetPhieuXetNghiem();
    handleGetPreviousResult();
    handleClearContext();
  };

  return (
    <>
      <TableCollapseCustom
        columns={columnThongTinXetNghiem}
        data={dataKQ || []}
        className="overflow-auto spaces"
        classNameTable="spaces W-1000"
        height="calc(100vh - 315px)"
        childrenField="subs"
        handleContextMenu={handleContextMenu}
        isFromTabXN
      />
      {openChuyenDV && (
        <DialogChuyenDV
          handleClose={() => setOpenChuyenDV(false)}
          handleChuyenPhongDV={handleChuyenPhongDV}
        />
      )}

      {contextMenu && !itemService && (
        <WfContextMenu
          tracker={WfConstTracker.ORDER_CIS}
          configuration={configurationContextMenu}
          wfWorkflowsReq={{
            objectId: rowSelect?.orderId,
            status: rowSelect?.oldStatusId,
          }}
          title="Phiếu thực hiện"
          contextMenu={contextMenu}
          setContextMenu={setContextMenu}
          handleClick={actionClick}
        />
      )}
      {itemService && !rowSelect?.isCanceled && (
        <ContextMenu
          handleCloseMenu={() => {
            handleClearContext();
          }}
          target={contextMenu}
          tracker={itemService?.trackerCode}
          objectId={itemService?.objectId}
          className="context-menu-xn"
          additionalFunc={actionChanged}
          handleClick={actionClick}
          hiddenWfButton={benhNhanInfo?.isReturnResult}
          ButtonProps={
            !itemService?.attributes?.isComponent &&
            itemService.currStatusCode === WF_STEP.TEST_ORDER_RESULT ? (
              <li
                id="wf-print-button"
                onClick={() => {
                  setOpenPrintDialog(true);
                  handleClearContext()
                }}
                className={`position-relative p-1 drop-list-item d-flex justify-content-between align-items-center border-bottom`}
              >
                <span className="fs-4 min-w-15px text-center">
                  <i className="bi bi-printer" />
                </span>
                <div className="d-flex justify-content-between align-items-center w-100 ps-2">
                  <span>In kết quả</span>
                </div>
              </li>
            ) : (
              <></>
            )
          }
        />
      )}

      {openPrintDialog && (
        <FormInPhieuDialog
          show={openPrintDialog}
          onHide={() => {
            setOpenPrintDialog(false);
          }}
          fetchExport={inKetQuaDichVu}
          params={{
            orderTermId: rowSelect?.termId,
            testGroupId: benhNhanInfo?.testGroupId,
          }}
        />
      )}
    </>
  );
};

export default BangLayMauBenhPham;
