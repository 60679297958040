import { useEffect, useRef, useState } from 'react';

const withBarcodeScan = (WrapperComponent: any) => {
	return (props: any) => {
		const [value, setValue] = useState<string>('');
		const inputRef = useRef<HTMLInputElement>(null);

		useEffect(() => {
			if (document.activeElement) {
				(document.activeElement as HTMLElement).blur();
			}

			if (document.hasFocus()) {
				document.addEventListener('keydown', onDocumentKeydown);
			}

			return () => {
				document.removeEventListener('keydown', onDocumentKeydown);
			};
		}, []);

		const onDocumentKeydown = (e: KeyboardEvent) => {
			const focusElement = document.querySelector(':focus');
			const inputQRElement = inputRef.current;
			const END_KEYS = ['Enter', 'Tab'];
			const keyPressed = e.key;

			if (!END_KEYS.includes(keyPressed)) {
				if (!focusElement && inputQRElement) {
					inputQRElement.focus();
				}
			}

			if (END_KEYS.includes(keyPressed)) {
				setValue(inputQRElement!.value);

				setTimeout(() => {
					inputQRElement!.value = '';
					setValue('');
				}, 2000);	
			}

			if (!focusElement?.contains(inputQRElement)) {
				inputQRElement!.value = '';
				setValue('');
			}
		};

		return (
			<>
				<input
					ref={inputRef}
					type="text"
					className="spaces w-0vw h-0 p-0 m-0 border-0 d-flex"
				/>
				<WrapperComponent {...props} barcode={value} />;
			</>
		);
	};
};

export default withBarcodeScan;
