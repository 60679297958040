import Fuse from 'fuse.js';
import { useEffect, useRef } from 'react';
import { KEY_DS_DANH_MUC_TIEP_DON } from '../../utils/Constant';
import { decodeHEXString } from '../../utils/FormatUtils';
import { getIndexedDBItem } from '../../utils/IndexedDB';
import { IDanhSachDanhMuc } from '../models/TiepDonModel';

const useReadQRCode = () => {
	const categoryRef = useRef<any>(null);

	useEffect(() => {
		getDanhMuc();
	}, []);

	const getDanhMuc = async () => {
		const danhMuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
		categoryRef.current = danhMuc;
	};

	const convertNgaySinh = (value: string = '') => {
		if (value.includes('/')) {
			const [ngaySinh, thangSinh, namSinh] = value.split('/');
			return {
				ngaySinh,
				thangSinh,
				namSinh,
			};
		}

		let ngaySinh = null,
			thangSinh = null,
			namSinh = null;

		if (value.length === 8) {
			ngaySinh = value.slice(0, 2);
			thangSinh = value.slice(2, 4);
			namSinh = value.slice(4);
		}

		if (value.length === 6) {
			thangSinh = value.slice(0, 2);
			namSinh = value.slice(2);
		}

		if (value.length === 4) {
			namSinh = value;
		}

		return {
			ngaySinh,
			thangSinh,
			namSinh,
		};
	};

	const getCategoryOptionFromName = (value: string, options: any[]) => {
		const fuse = new Fuse(options, {
			includeScore: true,
			includeMatches: true,
			useExtendedSearch: true,
			ignoreLocation: true,
			isCaseSensitive: true,
			threshold: 0.1,
			keys: ['name'],
		});

		const result = fuse
			.search(`"${value}"`, {
				limit: 1,
			})
			.map(record => record.item);
		return result[0];
	};

	const getAddressInfo = (address: string) => {
		const [town, ward, district, province] = address.split(/,/);

		const provinceOption = getCategoryOptionFromName(province, categoryRef.current.provinces);
		const districtOption = getCategoryOptionFromName(district, categoryRef.current.districts);
		const wardOption = getCategoryOptionFromName(ward, categoryRef.current.subDistricts);

		return {
			town,
			ward,
			district,
			province,
			provinceOption,
			districtOption,
			wardOption,
		};
	};

	const getBirthDateInfo = (birthDate: string) => {
		const { ngaySinh, thangSinh, namSinh } = convertNgaySinh(birthDate);

		return {
			ngaySinh,
			thangSinh,
			namSinh,
		};
	};

	const readCanCuocQRCode = (qrcode: string) => {
		const rawValue = qrcode.split(/\|/);
		const [soCccd, soCmnd, hoTen, ngayThangNamSinh, gioiTinh, diaChi = '', ngayLamCanCuoc] =
			rawValue;

		const genderOption = getCategoryOptionFromName(gioiTinh, categoryRef.current.listGioiTinh);

		return {
			hoTen,
			soCccd,
			soCmnd,
			ngayLamCanCuoc,
			diaChi,
			gioiTinh,
			genderOption,
			...getBirthDateInfo(ngayThangNamSinh),
			...getAddressInfo(diaChi),
		};
	};

	const readBHYTQRCode = (qrcode: string) => {
		const rawValue = qrcode.split(/\|/);

		const [
			maSoBHYT,
			hoTenEncode,
			ngayThangNamSinh,
			maGioiTinh,
			diaChiEncode,
			maNoiKCB,
			giaTriSuDungTuNgay,
			,
			,
			,
			,
			maMucHuong,
			thoiDiem5NamLienTuc,
			,
			,
		] = rawValue;

		const hoTen = decodeHEXString(hoTenEncode);
		const diaChi = decodeHEXString(diaChiEncode);
		const GENDER_MAPPER: Record<string, string> = {
			1: 'Nam',
			2: 'Nữ',
			3: 'Không xác định',
		};
		const genderOption = getCategoryOptionFromName(
			GENDER_MAPPER[maGioiTinh],
			categoryRef.current.listGioiTinh
		);

		return {
			maSoBHYT,
			hoTen,
			genderOption,
			maNoiKCB,
			giaTriSuDungTuNgay,
			maMucHuong,
			thoiDiem5NamLienTuc,
			...getBirthDateInfo(ngayThangNamSinh),
			...getAddressInfo(diaChi),
		};
	};

	return {
		readCanCuocQRCode,
		readBHYTQRCode,
	};
};

export default useReadQRCode;
